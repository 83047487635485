import { ButtonGroup } from "@chakra-ui/react";
import { ReactElement, ReactNode } from "react";
import { useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { AlertStack } from "../ui/AlertStack";
import { DependencyStatusAlert } from "../ui/DependencyStatusAlert";
import { DocsLink } from "../ui/DocsLink";
import { PageContainer } from "../ui/PageContainer";
import { Breadcrumb, PageHeader } from "../ui/PageHeader";
import { PageLayout } from "../ui/PageLayout";
import { ResourceArchivedAlert } from "../ui/ResourceStatus/ResourceArchivedAlert";
import { ResourceStatusAlert } from "../ui/ResourceStatus/ResourceStatusAlert";
import { SidebarLayout } from "../ui/SidebarLayout/SidebarLayout";
import { useSidebarLayoutState } from "../ui/SidebarLayout/useSidebarLayoutState";
import { PersonaSetFragment } from "./__generated__/PersonaSetFragment";
import { PersonaSetActionsMenu } from "./PersonaSetActionsMenu";
import { PersonaSetTabs } from "./PersonaSetTabs";
import { PersonaSetsInfoSidebar } from "./PersonasInfoSidebar";

interface CohortsSidebarLayoutProps {
  title: string;
  personaSet?: PersonaSetFragment;
  children: ReactNode;
  lastCrumb?: Breadcrumb;
  containContent?: boolean;
  isSidebarOpenByDefault?: boolean;
}

/**
 * Renders the shared elements of a cohort show, edit, and create page.
 */
export function PersonaSetsSidebarLayout({
  title,
  personaSet,
  children,
  lastCrumb,
  containContent = true,
  isSidebarOpenByDefault = false,
}: CohortsSidebarLayoutProps): ReactElement {
  const { navigate } = useRouter();

  const sidebarProps = useSidebarLayoutState("personas-right", {
    defaultIsOpen: isSidebarOpenByDefault,
  });

  const crumbs = [
    {
      label: "Persona sets",
      routeName: ROUTE_NAMES.PERSONAS,
    },
  ];

  if (lastCrumb) crumbs.push(lastCrumb);

  const actions = (
    <ButtonGroup>
      <DocsLink resourceType="persona_sets" size="sm" />
      {personaSet ? (
        <PersonaSetActionsMenu
          personaSet={personaSet}
          options={{
            info: sidebarProps.onToggle,
            view: false,
            rename: true,
            api: true,
            delete: true,
            archive: true,
          }}
          onDeleteComplete={() => navigate(ROUTE_NAMES.PERSONAS)}
        />
      ) : (
        <PersonaSetActionsMenu options={{ info: sidebarProps.onToggle }} />
      )}
    </ButtonGroup>
  );

  return (
    <PageLayout analyticsStackName="persona-sets">
      <PageHeader
        title={title}
        rightContent={actions}
        crumbs={crumbs}
        backBtnProps={{
          label: "Back to persona sets",
          routeName: ROUTE_NAMES.PERSONAS,
        }}
      />
      <SidebarLayout
        rightSidebarProps={{
          isOpen: sidebarProps.isOpen,
          onClose: sidebarProps.onToggle,
          title: "About persona sets",
          children: <PersonaSetsInfoSidebar />,
          width: 360,
        }}
      >
        <PageContainer pt={3} py={0}>
          {personaSet ? (
            <>
              <PersonaSetTabs personaSet={personaSet} />
              <AlertStack>
                <ResourceArchivedAlert resource={personaSet} />
                <DependencyStatusAlert resource={personaSet} />
                <ResourceStatusAlert resource={personaSet} />
              </AlertStack>
            </>
          ) : null}
        </PageContainer>

        {containContent ? (
          <PageContainer pb={6} py={0}>
            {children}
          </PageContainer>
        ) : (
          children
        )}
      </SidebarLayout>
    </PageLayout>
  );
}
