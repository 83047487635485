import isEqual from "lodash/isEqual";
import { useEffect, useRef } from "react";

// state can be any object
export type StateToConfirm = unknown;

type CallbackFunction = (...args: unknown[]) => void;

/**
 * Custom hook that checks if a given state has changed since the initial render.
 *
 * @param state - The state to monitor for changes.
 * @returns A boolean indicating whether the state has changed since the initial render.
 */
export function useHasStateChanged(state?: StateToConfirm): boolean {
  const initialStateRef = useRef<StateToConfirm | null>(null);

  useEffect(() => {
    if (state === undefined) return;
    if (initialStateRef.current === null) {
      initialStateRef.current = state;
    }
  }, [state]);

  // comparison only works for objects being passed as state
  if (!state || !initialStateRef.current) return false;

  return !isEqual(state, initialStateRef.current);
}

/**
 * A custom hook that detects changes in state and prompts the user for confirmation
 * before executing a callback function.
 */
export function useConfirmOnStateChange({
  state,
  callback,
  enabled = true,
}: {
  state?: StateToConfirm;
  callback: CallbackFunction;
  enabled?: boolean;
}): CallbackFunction {
  const hasChanged = useHasStateChanged(state);

  /**
   * A wrapped callback that checks for state changes and prompts for confirmation.
   */
  const wrappedCallback: CallbackFunction = (...args: unknown[]) => {
    if (!enabled || state === undefined) {
      // If the confirmation logic is disabled, proceed without confirmation
      callback(...args);
      return;
    }

    // Compare the initial and current state data

    if (hasChanged) {
      const confirmProceed = window.confirm(
        "You have unsaved changes. Do you want to proceed?"
      );
      if (confirmProceed) {
        callback(...args);
      }
      // If the user cancels, do nothing
    } else {
      // No changes detected, proceed without confirmation
      callback(...args);
    }
  };

  return wrappedCallback;
}
