import { ResourceStatus } from "@fdy/faraday-js";
import { Warning } from "@phosphor-icons/react";
import { useQuery } from "@tanstack/react-query";
import { ReactElement } from "react";

import { persona_setQueryKeys } from "../../../../hooks/api";
import { useFdyClient } from "../../../../services/FdyClientProvider";
import { PersonaAvatar } from "../../../personas/analysis/PersonaAvatar";
import { Card } from "../../Card";

interface PersonaSegmentCard {
  id: string;
  color: string;
  onRemoveClick?: () => void;
}

export function PersonaSegmentCard({
  id,
  color,
  onRemoveClick,
}: PersonaSegmentCard): ReactElement {
  const client = useFdyClient();
  const { data: personaSets, isLoading } = useQuery({
    queryKey: persona_setQueryKeys.list,
    queryFn: () => client.personaSets.getPersonaSets(),
  });

  const personaSet = personaSets?.find((ps) =>
    ps.personas?.find((p) => p.id === id)
  );
  const persona = personaSet?.personas?.find((p) => p.id === id);

  if (!persona) {
    return <Card color={color} icon={<Warning />} name="Persona not found" />;
  }

  return (
    <Card
      loading={isLoading}
      icon={<PersonaAvatar persona={persona} size="xs" />}
      name={persona.name}
      count={
        personaSet?.status !== ResourceStatus.Ready
          ? "Pending..."
          : persona.individuals_count
      }
      color={color}
      onRemoveClick={onRemoveClick}
    />
  );
}
