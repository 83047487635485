import { ReactElement } from "react";

import { SegmentType } from "../../../__generated__/globalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useCohortListSuspenseQuery } from "../../../hooks/api";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { useExploreParams } from "../beta/useExploreParams";
import { EmptyPresets } from "./EmptyPresets";
import { PresetList } from "./PresetList";

/**
 * Renders cohorts which are enabled to be shown in the explore analysis view.
 */
export function ExploreCohorts(): ReactElement {
  const { addSegment } = useExploreParams();

  const { data: cohorts } = useCohortListSuspenseQuery({
    select: (data) => data.filter((c) => c.explore && c.last_updated_output_at),
  });

  return (
    <PresetList.Actionable
      routeName={ROUTE_NAMES.COHORTS}
      label="Manage cohorts"
      analyticsName="cohorts"
    >
      {cohorts.length > 0 ? (
        <PresetList>
          {cohorts.map((cohort) => (
            <PresetList.Button
              key={cohort.id}
              icon={<ResourceIcon.cohorts />}
              label={cohort.name}
              onClick={() =>
                addSegment({
                  id: cohort.id,
                  type: SegmentType.Cohort,
                })
              }
            />
          ))}
        </PresetList>
      ) : (
        <EmptyPresets
          routeName={ROUTE_NAMES.COHORTS}
          icon={ResourceIcon.cohorts}
          name="cohorts"
          cta="cohorts console"
        />
      )}
    </PresetList.Actionable>
  );
}
