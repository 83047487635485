import { Flex } from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useSojournerQueryWithAliasSubstitution } from "../../../services/sojournerApolloClient";
import { findConnectionTypeInfoBySlug } from "../../pipelines/connectionUtils";
import { ResourceLink } from "../../ui/ResourceLink";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { ResourceTable } from "../../ui/ResourceTable";
import { TimeAgo } from "../../ui/TimeAgo";
import { VendorLogo } from "../../ui/VendorLogo";
import {
  ConnectionsTableQuery,
  ConnectionsTableQuery_connections as Connection,
} from "../__generated__/ConnectionsTableQuery";
import { ConnectionsActionsMenu } from "../ConnectionsActionsMenu";
import { connectionsQuery } from "../connectionsQuery";

const BLANK_SLATE_PROPS = {
  title: "No Connections found",
  text: "Connections are bidirectional, used for both Datasets and Deployments (part of Pipelines).",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children:
      "If you are uploading CSVs, you can use Datasets without a Connection.",
  },
};

export function ConnectionsTable() {
  const { data, loading, error } =
    useSojournerQueryWithAliasSubstitution<ConnectionsTableQuery>(
      connectionsQuery
    );

  if (error) throw error;

  const tableColumns: ColumnDef<Connection>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        cell: ({ row }) => {
          const connectionType = findConnectionTypeInfoBySlug(
            row.original.options.type
          );
          return (
            <ResourceLink resource={row.original} sx={{ fontWeight: "bold" }}>
              <Flex gap={3} alignItems="center">
                <VendorLogo path={connectionType?.logo_url} dimension={20} />
                <span>{row.original.name}</span>
              </Flex>
            </ResourceLink>
          );
        },
      },
      {
        accessorKey: "datasets",
        header: "Datasets",
        cell: ({ row }) => row.original.datasets.length,
      },
      {
        accessorKey: "targets",
        header: "Targets",
        cell: ({ row }) => row.original.targets.length,
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => <ResourceStatusBadge resource={row.original} />,
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: ({ row }) => <TimeAgo date={row.original.createdAt} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "lastUpdatedConfigAt",
        header: "Modified",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedConfigAt} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "lastUpdatedOutputAt",
        header: "Processed",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedOutputAt} />,
        sortingFn: "datetime",
      },
      {
        id: "actions",
        header: "Actions",
        size: 64,
        enableSorting: false,
        cell: ({ row }) => (
          <ConnectionsActionsMenu
            connection={row.original}
            options={{
              view: true,
              edit: !row.original.managed,
              api: true,
              delete: true,
              archive: true,
            }}
            buttonProps={{ variant: "icon", my: -2 }}
          />
        ),
      },
    ],
    []
  );

  return (
    <ResourceTable<Connection>
      resourceType={ResourceType.Connections}
      blankslate={BLANK_SLATE_PROPS}
      loading={loading}
      data={data?.connections ?? []}
      columns={tableColumns}
      defaultSort={[
        {
          id: "createdAt",
          desc: true,
        },
      ]}
    />
  );
}
