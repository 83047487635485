import { gql, useQuery } from "@apollo/client";
import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { MutedText } from "../ui/MutedText";
import { PageContainer } from "../ui/PageContainer";
import { PageHeader } from "../ui/PageHeader";
import { PageLayout } from "../ui/PageLayout";
import { ResourceTableList } from "../ui/ResourceTableList";
import {
  LocationsQuery,
  LocationsQuery_locations,
} from "./__generated__/LocationsQuery";

const LOCATIONS_QUERY = gql`
  query LocationsQuery {
    locations: points_of_interest {
      id
      categories
      city
      description
      house_number_and_street
      postcode
      state
    }
  }
`;

function LocationRow({ location }: { location: LocationsQuery_locations }) {
  return (
    <Tr>
      <Td>{location.description ?? <MutedText>None</MutedText>}</Td>
      <Td>{location.house_number_and_street}</Td>
      <Td>{location.city}</Td>
      <Td>{location.state}</Td>
      <Td>{location.postcode}</Td>
      <Td>{location.categories?.join(", ")}</Td>
    </Tr>
  );
}

function LocationsTable() {
  const { data, loading, error } = useQuery<LocationsQuery>(LOCATIONS_QUERY);

  return (
    <div>
      <Table>
        <Thead>
          <Tr>
            <Th>Description</Th>
            <Th>Address</Th>
            <Th>City</Th>
            <Th>State</Th>
            <Th>Zip</Th>
            <Th>Tags</Th>
          </Tr>
        </Thead>
        <Tbody>
          <ResourceTableList
            error={error}
            loading={loading}
            blankSlateProps={{ title: "No locations" }}
            data={data?.locations}
            renderData={(loc) => <LocationRow location={loc} />}
          />
        </Tbody>
      </Table>
    </div>
  );
}

export function Locations() {
  const title = "Locations";
  return (
    <PageLayout analyticsStackName="locations">
      <PageHeader
        title={title}
        crumbs={[
          {
            label: title,
            routeName: ROUTE_NAMES.LOCATIONS,
          },
        ]}
      />
      <PageContainer>
        <LocationsTable />
      </PageContainer>
    </PageLayout>
  );
}
