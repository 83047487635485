import { Select } from "@chakra-ui/react";
import { ReactNode } from "react";

import { analyticsAttrs, useAnalyticsKey } from "./Analytics/AnalyticsStack";
import { FormField } from "./FormField";
import { Input } from "./Input";

export enum InputType {
  text = "text",
  integer = "number",
  boolean = "boolean",
  password = "password",
}

interface TextFieldProps {
  label: string;
  name: string;
  type: InputType;
  value: string;
  required?: boolean;
  dataType?: string;
  pattern?: string;
  title?: string;
  error?: string;
  hint?: ReactNode;
  onChange: (value: string) => void;
  disabled?: boolean;
  analyticsName?: string;
}

export function TextField({
  label,
  type,
  hint,
  error,
  required,
  dataType,
  name,
  pattern,
  title,
  onChange,
  value,
  disabled,
  analyticsName,
}: TextFieldProps) {
  const analyticsKey = useAnalyticsKey(analyticsName);
  function renderInput() {
    if (type === InputType.boolean) {
      return (
        <Select
          defaultValue={String(value) === "true" ? "true" : "false"}
          name={name}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          {...analyticsAttrs(analyticsKey)}
        >
          <option value="true">True</option>
          <option value="false">False</option>
        </Select>
      );
    }
    return (
      <Input
        name={name}
        type={type}
        defaultValue={value}
        required={required}
        title={title}
        pattern={pattern}
        onChange={(e) => onChange(e.target.value.trim())}
        disabled={disabled}
        {...analyticsAttrs(analyticsKey)}
      />
    );
  }

  return (
    <FormField
      label={label}
      helpText={hint}
      error={error}
      dataType={dataType}
      suffix={required ? undefined : "optional"}
    >
      {renderInput()}
    </FormField>
  );
}
