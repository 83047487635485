import { useEffect } from "react";
import { useBeforeUnload } from "react-use";

import {
  unsavedChangeMessage,
  useNavigationWarning,
} from "../components/ui/NavigationWarningProvider";
import { StateToConfirm, useHasStateChanged } from "./useConfirmOnStateChange";

/**
 * Tracks a resource's state and will present a window warning message
 * if user attempts to leave page without saving after state change. Returns
 * a setState function that should be placed where the page is being saved.
 */
export function useUnsavedChangesWarning({ state }: { state: StateToConfirm }) {
  const changed = useHasStateChanged(state);

  const { setWarnBeforeNavigate } = useNavigationWarning();

  useEffect(() => {
    setWarnBeforeNavigate(changed);
  }, [changed]);

  // warn on navigating away from the page
  useBeforeUnload(changed, unsavedChangeMessage);

  return { setWarnBeforeNavigate };
}
