import { ResourceStatus } from "@fdy/faraday-js";
import { ReactElement } from "react";

import { useCohortShowQuery } from "../../../../hooks/api";
import { ResourceIcon } from "../../../ui/ResourceIcon";
import { Card } from "../../Card";

interface Props {
  id: string;
  color: string;
  onRemoveClick?: () => void;
  disableRemove?: boolean;
}

export function CohortSegmentCard({
  id,
  color,
  onRemoveClick,
  disableRemove,
}: Props): ReactElement {
  const { data: cohort, isLoading } = useCohortShowQuery(id);

  return (
    <Card
      loading={isLoading}
      icon={<ResourceIcon.cohorts size={24} />}
      name={cohort?.name}
      count={
        cohort?.status !== ResourceStatus.Ready
          ? "Pending..."
          : cohort?.population_count
      }
      color={color}
      onRemoveClick={disableRemove ? undefined : onRemoveClick}
    />
  );
}
