import { useSuspenseQuery } from "@tanstack/react-query";

import { useFdyClient } from "../../../services/FdyClientProvider";

export const graphKeys = {
  list: ["graph"],
};

// Consider updating the api to accept a resource ID param so it
// doesn't return the whole account graph
export const useGraph = () => {
  const client = useFdyClient();

  return useSuspenseQuery({
    queryKey: graphKeys.list,
    queryFn: () => client.graph.getGraph(),
  });
};
