import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ResourceStatus, ResourceType } from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";
import * as React from "react";

import { traitLiterate } from "../../utils/traitUtils";
import { ActionMenuChiefLink } from "../ui/ActionMenuChiefLink";
import { ApiShortcutsModal } from "../ui/ApiDocs/ApiShortcutsModal";
import { ArchiveMenuButton } from "../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../ui/ResourceDeleteButton";
import { TraitsTableQuery_traits3 as Trait } from "./__generated__/TraitsTableQuery";

export function TraitActionsMenu({
  trait,
  onDeleteComplete,
}: {
  trait: Trait;
  onDeleteComplete: () => void;
}) {
  const menuBtnRef = React.useRef<HTMLButtonElement>(null);
  const apiShortcutsModalProps = useDisclosure();

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          ref={menuBtnRef}
          aria-label={`Toggle ${traitLiterate(trait)} actions`}
          icon={<DotsThreeOutline weight="fill" />}
          variant="icon"
          color="fdy_gray.600"
          size="sm"
          sx={{ my: -2 }}
        />
        <MenuList>
          <MenuItem onClick={apiShortcutsModalProps.onOpen}>
            API Shortcuts
          </MenuItem>

          <ActionMenuChiefLink resource={trait} />

          <ArchiveMenuButton
            name={trait.name}
            resourceId={trait.id}
            resourceType="traits"
            archivedAt={trait.archivedAt}
            focusAfterCloseRef={menuBtnRef}
            status={trait.status}
          />

          <ResourceDeleteButton
            name={trait.name}
            resourceId={trait.id}
            resourceType="traits"
            isMenuItem
            resourceStatus={ResourceStatus.Ready} // traits don't have status, so fake it
            onDeleteComplete={onDeleteComplete}
          />
        </MenuList>
      </Menu>
      {apiShortcutsModalProps.isOpen && trait && (
        <ApiShortcutsModal
          resource={{ id: trait.id, resource_type: ResourceType.Traits }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
