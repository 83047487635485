import { ResourceLink } from "../ui/ResourceLink";
import {
  TraitsTableQuery_datasets,
  TraitsTableQuery_traits3,
} from "./__generated__/TraitsTableQuery";

export function TraitDatasetLinks({
  trait,
  datasets,
}: {
  trait: TraitsTableQuery_traits3;
  datasets: TraitsTableQuery_datasets[];
}) {
  const relevantDatasets = datasets.filter((dataset) => {
    const datasetTraits = dataset.outputToTraits as {
      [traitName: string]: { columnName: string };
    };
    const filteredTraits = Object.keys(datasetTraits).filter(
      (datasetTrait) => datasetTrait === trait.name
    );
    if (filteredTraits.length) return true;
  });

  return (
    <>
      {relevantDatasets.map((dataset) => (
        <ResourceLink resource={dataset} key={dataset.id} />
      ))}
    </>
  );
}
