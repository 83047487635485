import { ReactElement } from "react";

import { SegmentType } from "../../../__generated__/globalTypes";
import { ROUTE_NAMES } from "../../../constants/routeNames";
import { usePersonaSetListSuspenseQuery } from "../../../hooks/api";
import { PersonaAvatar } from "../../personas/analysis/PersonaAvatar";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { useExploreParams } from "../beta/useExploreParams";
import { EmptyPresets } from "./EmptyPresets";
import { PresetList } from "./PresetList";

/**
 * Renders a list of personas which are enabled to be shown in the explore analysis view.
 */
export function ExplorePersonas(): ReactElement {
  const { addSegment } = useExploreParams();

  const { data: personaSets } = usePersonaSetListSuspenseQuery({
    select: (data) =>
      data.filter((ps) => ps.explore && ps.last_updated_output_at),
  });

  return (
    <PresetList.Actionable
      routeName={ROUTE_NAMES.PERSONAS}
      label="Manage personas"
      analyticsName="personas"
    >
      {personaSets.length > 0 ? (
        <PresetList>
          {personaSets.map((ps) => (
            <PresetList.Section
              key={ps.id}
              icon={ResourceIcon.persona_sets}
              label={ps.name}
            >
              {ps.personas?.map((persona) => (
                <PresetList.Button
                  key={persona.id}
                  icon={<PersonaAvatar persona={persona} size="xs" />}
                  label={persona.name}
                  onClick={() => {
                    addSegment({
                      id: persona.id,
                      type: SegmentType.Persona,
                    });
                  }}
                />
              ))}
            </PresetList.Section>
          ))}
        </PresetList>
      ) : (
        <EmptyPresets
          routeName={ROUTE_NAMES.PERSONAS}
          icon={ResourceIcon.persona_sets}
          name="personas"
          cta="personas console"
        />
      )}
    </PresetList.Actionable>
  );
}
