import { MenuItem } from "@chakra-ui/react";
import React from "react";

import { useAdmin } from "../../hooks/useAdmin";
import { createChiefUrl, RestOrGqlResource } from "../../utils/createChiefUrl";

interface ActionMenuChiefLinkProps {
  resource: RestOrGqlResource;
}

export function ActionMenuChiefLink({ resource }: ActionMenuChiefLinkProps) {
  const { admin } = useAdmin();

  if (admin) {
    return (
      <MenuItem as="a" href={createChiefUrl(resource)}>
        View in Chief (admin only)
      </MenuItem>
    );
  }

  return null;
}
