import {
  Flex,
  Select,
  Spacer,
  SystemStyleObject,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";

import {
  analyticsAttrs,
  useAnalyticsKeyGenerator,
} from "../../../ui/Analytics/AnalyticsStack";
import { SwitchV2 } from "../../../ui/Switch/SwitchV2";
import { TableBlankslate } from "../../../ui/TableV2/parts";
import { TargetMode } from "./TargetRepresentationRadios";
import {
  EditableColumns,
  targetStructureColStyle,
} from "./TargetStructureColumns";
import {
  DEFAULT_PRESET_STRING,
  presetsForMode,
  ruleForPreset,
  TargetStructureProps,
  TransformPresetType,
} from "./targetStructureUtils";

const tableStyle: SystemStyleObject = {
  boxShadow: 0,
  marginTop: 2,
};

/**
 * Renders the inputs for modifying a target's structure (export columns).
 */
export function TargetStructure({
  representation,
  hasCustomStructure,
  structure,
  transformPreset,
  defaultStructure,
  onChangeCustomStructure,
  customizable,
  onChangeTransformPreset,
}: TargetStructureProps) {
  const [isDragging, setIsDrigging] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(hasCustomStructure);

  const isDefault = transformPreset === DEFAULT_PRESET_STRING;

  const emptyTable = (
    <TableBlankslate
      title="No column metadata yet"
      text="Payload columns can be previewed/customized once the pipeline is built."
    />
  );

  const customizeComponent = (
    <SwitchV2
      px={2}
      label="Customize"
      tooltip={
        customizable
          ? "Customize the default name, order, and selection of columns to export"
          : "Pipeline must build before you can customize"
      }
      isDisabled={!isDefault || !customizable}
      isChecked={isEditing}
      onChange={() => {
        if (isEditing) {
          // reset to default when turning customization OFF
          onChangeCustomStructure(
            (defaultStructure || []).map((s) => ({
              ...s,
              show: true,
              newOutputCol: s.outputCol,
            }))
          );
        }
        setIsEditing(!isEditing);
      }}
      analyticsName="customize-columns"
    />
  );

  const mode = TargetMode[representation.mode as keyof typeof TargetMode];
  const getAnalyticsKey = useAnalyticsKeyGenerator("target-structure");
  return (
    <>
      <Flex alignItems="center" mb={2}>
        <Select
          aria-label="Transform preset"
          mr={8}
          value={transformPreset}
          isDisabled={isEditing}
          onChange={(event) => {
            onChangeTransformPreset(event.target.value as TransformPresetType);
          }}
        >
          {presetsForMode[mode as TargetMode].map((preset) => (
            <option
              key={preset}
              value={preset}
              {...analyticsAttrs(
                getAnalyticsKey(
                  `${mode}-${preset.toLowerCase().replace(/_/g, "-")}`
                )
              )}
            >
              {isEditing ? "Custom" : ruleForPreset(mode, preset).literate}
            </option>
          ))}
        </Select>
        <Spacer minWidth={4} />
        {customizeComponent}
      </Flex>
      {isDefault ? (
        <>
          <Table sx={tableStyle} variant="fdy">
            <Thead mb={-4}>
              <Tr>
                {isEditing && <Th {...targetStructureColStyle}>Move</Th>}
                {isEditing && <Th {...targetStructureColStyle}>Show</Th>}
                <Th>Output Column Name</Th>
                {isEditing && (
                  <Th {...targetStructureColStyle} pl={4}>
                    Info
                  </Th>
                )}
              </Tr>
            </Thead>
            {!isEditing && (
              <Tbody>
                {defaultStructure && defaultStructure.length > 0
                  ? defaultStructure.map((column, idx) => (
                      <Tr key={idx}>
                        <Td
                          style={{ paddingTop: "12px", paddingBottom: "12px" }}
                        >
                          <Text>{column.outputCol}</Text>
                        </Td>
                      </Tr>
                    ))
                  : emptyTable}
              </Tbody>
            )}
          </Table>
          {isEditing &&
            (defaultStructure ? (
              <EditableColumns
                isDragging={isDragging}
                onDragStart={() => setIsDrigging(true)}
                onDragEnd={() => {
                  setIsDrigging(false);
                  window.getSelection()?.removeAllRanges();
                }}
                structure={structure}
                onChangeCustomStructure={onChangeCustomStructure}
              />
            ) : (
              emptyTable
            ))}
        </>
      ) : null}
    </>
  );
}
