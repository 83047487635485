import { AccordionV2 } from "../../../ui/AccordionV2";
import { CardV2 } from "../../../ui/Card/CardV2";
import { SkeletonCard } from "../../../ui/SkeletonCard";
import { OutcomeFragment } from "../../__generated__/OutcomeFragment";
import { OutcomeAnalysisPerformanceModel } from "../__generated__/OutcomeAnalysisPerformanceModel";
import { OutcomeAnalysisQuery_outcomeAnalysis } from "../__generated__/OutcomeAnalysisQuery";
import {
  OutcomeAnalysisRecognitionGroupSelect,
  useRecognitionGroupState,
} from "../OutcomeAnalysisRecognitionGroupSelect";
import { outcomeTenureLabel } from "../outcomeTenureLabel";
import { useOutcomeAnalysisQuery } from "../useOutcomeAnalysisQuery";
import { useOutcomeUsesFirstPartyData } from "../useOutcomeUsesFirstPartyData";
import { OutcomeGradeBadge } from "./OutcomeGradeBadge";
import { OutcomeLiftRocGridChart } from "./OutcomeLiftRocGridChart";
import { OutcomePerformanceCharts } from "./OutcomePerformanceCharts";
import { OutcomePerformanceText } from "./OutcomePerformanceText";
import { OutcomeRocBarChart } from "./OutcomeRocBarChart";

interface OutcomePerformanceCardProps {
  outcome: OutcomeFragment;
}

function OutcomePerformanceFallback({
  rocAuc,
  liftValue,
  usesFPD,
}: {
  rocAuc: number | null;
  liftValue: number | null;
  usesFPD: boolean;
}) {
  if (!rocAuc) return null;

  const chart =
    liftValue === null ? (
      <OutcomeRocBarChart rocAuc={rocAuc} usesFPD={usesFPD} />
    ) : (
      <OutcomeLiftRocGridChart
        rocAuc={rocAuc}
        liftValue={liftValue}
        usesFPD={usesFPD}
      />
    );

  return (
    <CardV2 title="Performance">
      <OutcomePerformanceText
        rocAuc={rocAuc}
        liftValue={liftValue}
        usesFPD={usesFPD}
      />
      {chart}
    </CardV2>
  );
}

function OutcomePerformanceAccordionItem({
  eligibleCohortName,
  performance,
  title,
  usesFPD,
}: {
  performance: OutcomeAnalysisPerformanceModel;
  eligibleCohortName: string | null;
  title?: string;
  usesFPD: boolean;
}) {
  const { rocAuc, liftValue } = performance;

  const titleNode = (
    <span style={{ fontWeight: "normal" }}>
      {outcomeTenureLabel(performance)}
      {performance.tenureStart === null && performance.tenureEnd === null
        ? " "
        : " in "}
      <strong>{eligibleCohortName}</strong>
    </span>
  );

  return (
    <AccordionV2.Item
      title={title ?? titleNode}
      afterTitle={
        <OutcomeGradeBadge
          usesFPD={usesFPD}
          rocAuc={rocAuc}
          liftValue={liftValue}
        />
      }
    >
      <OutcomePerformanceCharts performance={performance} usesFPD={usesFPD} />
    </AccordionV2.Item>
  );
}

function OutcomePerformanceGroups({
  outcomeAnalysis,
  usesFPD,
  outcome,
}: {
  outcomeAnalysis: OutcomeAnalysisQuery_outcomeAnalysis;
  usesFPD: boolean;
  outcome: OutcomeFragment;
}) {
  const { availableGroups, selectedGroup, setSelectedGroup } =
    useRecognitionGroupState(outcomeAnalysis.overallPerformance);

  const overallGroup = outcomeAnalysis.overallPerformance[selectedGroup];
  const tenureGroup = outcomeAnalysis.tenurePerformances?.[selectedGroup];

  // If there is no overall performance, don't render the card.
  // It would be included for either dynamic or non-dynamic outcomes.
  if (!overallGroup) return null;

  const cardText = (
    <>
      Dynamic prediction is active. Predictions will depend on how long a person
      has been a member of <strong>{outcome.eligibleCohortName}</strong>.
    </>
  );

  const select = (
    <OutcomeAnalysisRecognitionGroupSelect
      groups={availableGroups}
      value={selectedGroup}
      onChange={setSelectedGroup}
    />
  );

  if (tenureGroup && tenureGroup.length > 0) {
    return (
      <CardV2 title="Performance" text={cardText}>
        {select}

        <AccordionV2 defaultIndex={0} allowToggle>
          {overallGroup && (
            <OutcomePerformanceAccordionItem
              title="Overview"
              performance={overallGroup}
              usesFPD={usesFPD}
              eligibleCohortName={outcome.eligibleCohortName}
            />
          )}

          {tenureGroup.map((perf, i) => {
            if (!perf) return null;
            return (
              <OutcomePerformanceAccordionItem
                key={i}
                performance={perf}
                usesFPD={usesFPD}
                eligibleCohortName={outcome.eligibleCohortName}
              />
            );
          })}
        </AccordionV2>
      </CardV2>
    );
  }

  // if the outcome is non-dynamic, render the single overall performance chart
  if (overallGroup) {
    return (
      <CardV2 title="Performance">
        {select}
        <OutcomePerformanceCharts
          usesFPD={usesFPD}
          performance={overallGroup}
        />
      </CardV2>
    );
  }

  return null;
}

export function OutcomePerformanceCard({
  outcome,
}: OutcomePerformanceCardProps) {
  const { data, loading } = useOutcomeAnalysisQuery(outcome);

  const { loading: cohortLoading, usesFPD } =
    useOutcomeUsesFirstPartyData(outcome);

  if (loading || cohortLoading) return <SkeletonCard />;

  const { outcomeAnalysis } = data ?? {};

  if (!outcomeAnalysis) {
    return (
      <OutcomePerformanceFallback
        rocAuc={outcome.rocAuc}
        liftValue={outcome.liftValue}
        usesFPD={usesFPD}
      />
    );
  }

  return (
    <OutcomePerformanceGroups
      outcomeAnalysis={outcomeAnalysis}
      outcome={outcome}
      usesFPD={usesFPD}
    />
  );
}
