import { gql } from "@apollo/client";
import { ResourceType } from "@fdy/faraday-js";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useSojournerQuery } from "../../../services/sojournerApolloClient";
import { ResourceLink } from "../../ui/ResourceLink";
import { ResourceStatusBadge } from "../../ui/ResourceStatus/ResourceStatusBadge";
import { ResourceTable } from "../../ui/ResourceTable";
import { TimeAgo } from "../../ui/TimeAgo";
import {
  PipelinesTableQuery,
  PipelinesTableQuery_scopes as Scope,
} from "../__generated__/PipelinesTableQuery";
import { targetFragment } from "../deployment/targetFragment";
import { PipelinesActionsMenu } from "../PipelinesActionsMenu";
import { pipelinesQuery } from "../pipelinesQuery";
import { TargetsQuery } from "./__generated__/TargetsQuery";
import { ScopePayloadPill } from "./ScopePayloadPill";
import { ScopePopulationPill } from "./ScopePopulationPill";
import { ScopeTargetsPill } from "./ScopeTargetsPill";

const BLANK_SLATE_PROPS = {
  title: "No pipelines found",
  text: "Pipelines use data from Datasets in the form of Cohorts, Outcomes, and/or Persona Sets, and deploy them to Connections or to a downloadable CSV.",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children: "Start by adding a Dataset if you haven't already.",
  },
};

export const TARGETS_QUERY = gql`
  query TargetsQuery {
    targets {
      ...TargetFragment
    }
  }
  ${targetFragment}
`;

export function PipelinesTable() {
  const { data, loading, error } = useSojournerQuery<PipelinesTableQuery>(
    pipelinesQuery,
    {
      pollInterval: 10000,
    }
  );

  if (error) throw error;

  const {
    data: targetsData,
    loading: targetsLoading,
    error: targetsError,
  } = useSojournerQuery<TargetsQuery>(TARGETS_QUERY);

  if (targetsError) throw targetsError;

  const tableColumns: ColumnDef<Scope>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 200,
        cell: ({ row }) => (
          <ResourceLink resource={row.original} sx={{ fontWeight: "bold" }} />
        ),
      },
      {
        id: "population",
        header: "Population",
        cell: ({ row }) => <ScopePopulationPill scope={row.original} />,
      },
      {
        id: "payload",
        header: "Payload",
        cell: ({ row }) => <ScopePayloadPill scope={row.original} />,
      },
      {
        id: "deployments",
        header: "Deployments",
        cell: ({ row }) => (
          <ScopeTargetsPill
            targets={
              targetsData?.targets.filter(
                (t) => t.scopeId === row.original.id
              ) ?? []
            }
            loading={targetsLoading}
          />
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => <ResourceStatusBadge resource={row.original} />,
        sortingFn: "basic",
      },
      {
        accessorKey: "createdAt",
        header: "Created",
        cell: ({ row }) => <TimeAgo date={row.original.createdAt} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "lastUpdatedConfigAt",
        header: "Modified",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedConfigAt} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "lastUpdatedOutputAt",
        header: "Processed",
        cell: ({ row }) => <TimeAgo date={row.original.lastUpdatedOutputAt} />,
        sortingFn: "datetime",
      },
      {
        id: "actions",
        header: "Actions",
        size: 64,
        enableSorting: false,
        cell: ({ row }) => (
          <PipelinesActionsMenu
            scope={row.original}
            options={{
              view: true,
              rename: true,
              api: true,
              edit: true,
              delete: true,
              archive: true,
            }}
            buttonProps={{ variant: "icon", my: -2 }}
          />
        ),
      },
    ],
    [targetsData, targetsLoading]
  );

  return (
    <ResourceTable<Scope>
      resourceType={ResourceType.Scopes}
      blankslate={BLANK_SLATE_PROPS}
      loading={loading}
      data={data?.scopes ?? []}
      columns={tableColumns}
      defaultSort={[
        {
          id: "createdAt",
          desc: true,
        },
      ]}
    />
  );
}
