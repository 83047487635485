import styled from "@emotion/styled";
import {
  createRef,
  KeyboardEvent,
  ReactElement,
  ReactNode,
  useEffect,
} from "react";

import { theme } from "../../../constants/theme";
import { colors } from "../../../styles/chakra-theme-v2";
import { AnimatedZapLogo } from "../../ui/AnimatedZapLogo";
import { Input } from "../../ui/Input";
import { Scroll } from "../../ui/Scroll";
import { useFilterItems } from "../../ui/useFilterItems";
import { FacetButton } from "./FacetButton";

const StyledFacets = styled(Scroll)`
  background: ${colors.fdy_gray[100]};
  border-left: 1px solid ${colors.fdy_gray[300]};
`;

const StyledFacetsHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  padding: ${theme.space(3)} ${theme.space(4)};
  background: ${colors.fdy_gray[100]};
  border-bottom: 1px solid ${colors.fdy_gray[300]};
`;

const StyledListContainer = styled.div`
  padding: ${theme.space(3)} ${theme.space(4)};
  padding-bottom: ${theme.space(6)}; // extra padding for enter insights button
`;

interface FacetListProps<Item> {
  inputLabel?: string;
  defaultItems: Item[];
  loading?: boolean;
  itemToString: (item: Item) => string;
  selectedItem?: Item | null | undefined;
  onItemSelect?: (item: Item) => void;
  renderInputContainer?: (props: {
    children: ReactNode;
  }) => ReactElement | null;
  renderAfterItemLabel?: (props: {
    item: Item;
    active: boolean;
  }) => ReactElement | null;
}

/**
 * Renders a search field and a list of buttons to select from.
 * Manages some specific keyboard interactions and more:
 * - When focusing search field, pressing up/down selects prev/next item in the list
 * - When focusing button in list, do same as above
 * - Typing in search field narrows list
 * - If the the new search result does not contain the already selected item,
 *   auto select the first item in the list.
 * - Clearing the text field maintains the selected item.
 * - TODO: scroll the selected item into view when search is cleared
 */
export function FacetList<Item>({
  defaultItems,
  loading,
  renderInputContainer,
  itemToString,
  onItemSelect = (f) => f,
  selectedItem,
  inputLabel,
  renderAfterItemLabel,
}: FacetListProps<Item>): ReactElement {
  // make the list of items filterable by text field
  const { items, getInputProps } = useFilterItems<Item>({
    items: defaultItems,
    itemToString,
  });

  // store an array of refs for transfering focus between buttons
  const refList = items.map(() => createRef<HTMLButtonElement>());

  function isItemSelected(item: Item) {
    if (!selectedItem) return false;
    return itemToString(item) === itemToString(selectedItem);
  }

  useEffect(() => {
    // if the new list of results does not contain the already selected facet,
    // switch to the first item in the new list
    if (
      items &&
      items.length &&
      (!selectedItem || !items.find(isItemSelected))
    ) {
      onItemSelect(items[0]);
    }
  }, [items]);

  /**
   * Select an item (pass it to parent component callback) and optionally focus it.
   * @param index
   * @param focus
   */
  function selectItemAtIndex(index: number, focus?: boolean) {
    const item = items[index];

    // ensure the index passed is an actual item in the list
    if (!item) return;

    onItemSelect(item);

    if (focus) {
      const node = refList[index]?.current;
      node?.focus();
    }
  }

  /**
   * Handle button press of either the input search field
   * or the buttons in the list.
   * @param index
   * @param evt
   */
  function handleButtonKeydown(index: number, evt: KeyboardEvent) {
    if (evt.key === "ArrowDown") {
      selectItemAtIndex(index + 1, true);
    } else if (evt.key === "ArrowUp") {
      selectItemAtIndex(index - 1, true);
    }
  }

  function handleButtonClick(item: Item) {
    onItemSelect(item);
  }

  if (loading) {
    return (
      <StyledFacets>
        <AnimatedZapLogo />
      </StyledFacets>
    );
  }

  // store the active index for up/down press in search field
  const activeIndex = items.findIndex(isItemSelected);

  const searchInput = (
    <Input
      aria-label={inputLabel || "Search"}
      type="text"
      placeholder="Search..."
      {...getInputProps()}
      onKeyDown={(e) => handleButtonKeydown(activeIndex, e)}
      autoFocus
      analyticsName="explore-facet-search"
    />
  );

  const header = renderInputContainer
    ? renderInputContainer({
        children: searchInput,
      })
    : searchInput;

  return (
    <StyledFacets>
      {header && <StyledFacetsHeader>{header}</StyledFacetsHeader>}
      <StyledListContainer>
        <ol>
          {items.map((item, i) => {
            const active = isItemSelected(item);
            return (
              <li key={i}>
                <FacetButton
                  active={active}
                  label={itemToString(item)}
                  afterLabel={
                    renderAfterItemLabel
                      ? renderAfterItemLabel({ item, active })
                      : null
                  }
                  onClick={() => handleButtonClick(item)}
                  onKeyDown={(e) => handleButtonKeydown(i, e)}
                  tabIndex={0}
                  ref={refList[i]}
                />
              </li>
            );
          })}
        </ol>
      </StyledListContainer>
    </StyledFacets>
  );
}
