import { ButtonGroup } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { DocsLink } from "../../ui/DocsLink";
import { LinkButton } from "../../ui/LinkButton";
import { PageContainer } from "../../ui/PageContainer";
import { PageHeader } from "../../ui/PageHeader";
import { PageIntroText } from "../../ui/PageIntroText";
import { PageLayout } from "../../ui/PageLayout";
import { UsageWarningNotice } from "../../ui/UsageWarningNotice";
import { PersonaSetsTable } from "./PersonaSetsTable";

export function PersonaSetsListPage() {
  const cta = (
    <ButtonGroup>
      <DocsLink resourceType="persona_sets" />
      <LinkButton
        routeName={ROUTE_NAMES.PERSONAS_NEW}
        leftIcon={<Plus weight="bold" />}
        analyticsName="new-persona-set"
      >
        New persona set
      </LinkButton>
    </ButtonGroup>
  );

  return (
    <PageLayout analyticsStackName="persona-sets-list">
      <PageHeader
        title="Persona sets"
        crumbs={[
          {
            label: "Persona sets",
            routeName: ROUTE_NAMES.PERSONAS,
          },
        ]}
        rightContent={cta}
      />
      <PageContainer size="fdy_lg">
        <UsageWarningNotice resource="persona_sets" />
        <PageIntroText>
          Persona sets are the result of persona predictions, in which Faraday
          uses AI to organize individuals within a cohort into thematic groups
          of people based on which traits–in both the Faraday Identity Graph and
          your data–are most effective. You can use your persona sets to guide
          personalized outreach, segment & analyze customers, and more.
        </PageIntroText>
        <PersonaSetsTable />
      </PageContainer>
    </PageLayout>
  );
}
