import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useRef } from "react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { ActionMenuChiefLink } from "../ui/ActionMenuChiefLink";
import { ApiShortcutsModal } from "../ui/ApiDocs/ApiShortcutsModal";
import { Link } from "../ui/Link";
import { RenameModal } from "../ui/RenameModal";
import { ArchiveMenuButton } from "../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../ui/ResourceDeleteButton";
import { PipelineFragment } from "./__generated__/PipelineFragment";
import { useUpdateScope } from "./useUpdateScope";

type ActionMenuOptions = {
  view?: boolean;
  info?: () => void;
  rename?: boolean;
  api?: boolean;
  edit?: boolean;
  archive?: boolean;
  delete?: boolean;
};

interface PipelinesActionsMenuProps {
  scope?: Pick<
    PipelineFragment,
    "__typename" | "id" | "name" | "status" | "archivedAt"
  >;
  options: ActionMenuOptions;
  onDeleteComplete?: () => void;
  buttonProps?: Pick<IconButtonProps, "my" | "variant">;
}

const defaultOptions = {
  view: false,
  rename: false,
  api: false,
  edit: false,
  archive: false,
  delete: false,
};

/**
 * Renders a dropdown menu with various actions a user can take on a given Pipeline/scope.
 */
export function PipelinesActionsMenu({
  scope,
  onDeleteComplete,
  options,
  buttonProps,
}: PipelinesActionsMenuProps) {
  const enabledOptions = { ...defaultOptions, ...options };
  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const renameModalProps = useDisclosure();
  const apiShortcutsModalProps = useDisclosure();

  const { updateScope, updating } = useUpdateScope({
    onCompleted() {
      renameModalProps.onClose();
    },
  });

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          ref={menuBtnRef}
          aria-label="Toggle Pipeline actions"
          icon={<DotsThreeOutline weight="fill" />}
          color="fdy_gray.600"
          size="sm"
          variant="secondary"
          {...buttonProps}
        />
        <MenuList>
          {enabledOptions.info && (
            <MenuItem onClick={enabledOptions.info}>About pipelines</MenuItem>
          )}

          {enabledOptions.view && scope && (
            <MenuItem
              as={Link}
              routeName={ROUTE_NAMES.PIPELINES_VIEW}
              params={{ id: scope.id }}
            >
              View details
            </MenuItem>
          )}

          {enabledOptions.rename && scope && (
            <MenuItem onClick={renameModalProps.onOpen}>Rename</MenuItem>
          )}

          {enabledOptions.edit && scope && (
            <MenuItem
              as={Link}
              routeName={ROUTE_NAMES.PIPELINES_EDIT}
              params={{ id: scope.id }}
            >
              Edit
            </MenuItem>
          )}

          {enabledOptions.api && (
            <MenuItem onClick={apiShortcutsModalProps.onOpen}>
              API shortcuts
            </MenuItem>
          )}

          {scope && <ActionMenuChiefLink resource={scope} />}

          {enabledOptions.archive && scope && (
            <ArchiveMenuButton
              name={scope.name}
              resourceId={scope.id}
              resourceType="scopes"
              archivedAt={scope.archivedAt}
              focusAfterCloseRef={menuBtnRef}
              status={scope.status}
            />
          )}

          {enabledOptions.delete && scope && (
            <ResourceDeleteButton
              name={scope.name}
              resourceId={scope.id}
              resourceType="scopes"
              onDeleteComplete={onDeleteComplete}
              isMenuItem
              resourceStatus={scope.status}
            />
          )}
        </MenuList>
      </Menu>

      {/* modals must be outside menu otherwise menu focus handling conflicts with modal's */}

      {scope && renameModalProps.isOpen && enabledOptions.rename && (
        <RenameModal
          defaultValue={scope.name}
          focusAfterCloseRef={menuBtnRef}
          onClose={renameModalProps.onClose}
          title="Rename pipeline"
          updateFn={(name) => updateScope(scope.id, { name })}
          updating={updating}
        />
      )}
      {scope && apiShortcutsModalProps.isOpen && enabledOptions.api && (
        <ApiShortcutsModal
          resource={{ id: scope.id, resource_type: ResourceType.Scopes }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
