import { gql } from "@apollo/client";
import { useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import {
  HUBSPOT_USER_UPDATED_CONNECTION,
  useHubSpotEvent,
} from "../../hooks/useHubspotEvent";
import { graphqlOptionsToSpec } from "../../services/connectionOptions";
import { useSojournerMutation } from "../../services/sojournerApolloClient";
import { AnimatedZapLogo } from "../ui/AnimatedZapLogo";
import { ManagedExplanation } from "../ui/ManagedExplanation";
import { PageContainer } from "../ui/PageContainer";
import { PageHeader } from "../ui/PageHeader";
import { PageLayout } from "../ui/PageLayout";
import {
  ConnectionUpdateMutation,
  ConnectionUpdateMutationVariables,
} from "./__generated__/ConnectionUpdateMutation";
import { connectionFragment } from "./connectionFragment";
import { ConnectionForm, ConnectionFormState } from "./ConnectionsForm";
import { useConnectionQuery } from "./ConnectionsShowPage";

export const connectionUpdateMutation = gql`
  mutation ConnectionUpdateMutation($connectionId: ID!, $connection: String!) {
    updateConnection(
      connectionId: $connectionId
      applicationJsonMergePatchInput: $connection
    ) {
      ...ConnectionDetails
    }
  }
  ${connectionFragment}
`;

/**
 * Renders a form to edit an existing connection
 */
export function ConnectionsEditPage() {
  const router = useRouter();
  const connectionId = router.getState().params.id;

  const { data, loading: loadingInit } = useConnectionQuery(connectionId);

  const track = useHubSpotEvent();

  const [updateConnection, { loading }] = useSojournerMutation<
    ConnectionUpdateMutation,
    ConnectionUpdateMutationVariables
  >(connectionUpdateMutation, {
    onCompleted(data) {
      if (data.updateConnection?.id) {
        track(HUBSPOT_USER_UPDATED_CONNECTION, {
          resource_id: data.updateConnection.id,
          resource_name: data.updateConnection.name,
        });
        router.navigate(ROUTE_NAMES.CONNECTIONS_SHOW, {
          id: data.updateConnection.id,
        });
      }
    },
  });

  if (loadingInit) return <AnimatedZapLogo />;
  const connection = data?.connection;
  if (!connection) {
    throw new Error(
      `BUG: Connection ${connectionId} not found, but sojourner did not throw an error`
    );
  }

  function onSave(input: ConnectionFormState) {
    updateConnection({
      variables: {
        connectionId,
        connection: JSON.stringify(input),
      },
    });
  }

  const defaultState = {
    name: connection.name,
    options: graphqlOptionsToSpec({ ...connection.options }),
  };

  return (
    <PageLayout analyticsStackName="connection-edit">
      <PageHeader
        title={connection.name}
        crumbs={[
          {
            label: "Connections",
            routeName: ROUTE_NAMES.CONNECTIONS,
          },
          {
            label: connection.name,
            routeName: ROUTE_NAMES.CONNECTIONS_SHOW,
            params: { id: connectionId },
          },
          {
            label: "Edit",
            routeName: ROUTE_NAMES.CONNECTIONS_EDIT,
            params: { id: connectionId },
          },
        ]}
        backBtnProps={{
          label: connection.name,
          routeName: ROUTE_NAMES.CONNECTIONS_SHOW,
          routeParams: { id: connectionId },
        }}
      />

      <PageContainer>
        {/* in case they get to this page via URL */}
        {connection.managed ? (
          <ManagedExplanation resourceType="connection" />
        ) : null}
        <ConnectionForm
          onSave={onSave}
          defaultState={defaultState}
          loading={loading}
          disabled={!!connection.managed}
          allowEmptySecrets
        />
      </PageContainer>
    </PageLayout>
  );
}
