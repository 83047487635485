export function outcomeTenureLabel({
  tenureStart,
  tenureEnd,
}: {
  tenureStart: number | null;
  tenureEnd: number | null;
}): string {
  if (tenureStart === null && tenureEnd === null) {
    return `Before joining`;
  } else if (tenureStart !== null && tenureEnd === null) {
    return `${tenureStart}+ days`;
  } else if (tenureEnd !== null) {
    return `${tenureStart ?? 0}-${tenureEnd} days`;
  } else {
    return `Unknown tenure`;
  }
}
