import { ApiOptions } from "../../../services/connectionOptions";
import { ConnectionTypeOptionsInfo } from "../../pipelines/connectionUtils";
import { FormFieldStack } from "../FormFieldStack";
import { Prose } from "../Prose";
import { InputType, TextField } from "../TextField";

function parseFieldValue(dataType: string, value: string) {
  switch (dataType) {
    case "boolean":
      // TODO: can we cast a value here instead somehow or ensure out selects use the same string types
      return value === "true";
    case "integer":
      return Number(value);
    default:
      return value;
  }
}

export interface ConnectionOptionFieldsProps {
  onFieldChange: (name: string, value: string | number | boolean) => void;
  values?: ApiOptions;
  fields: ConnectionTypeOptionsInfo[];
}

export function ConnectionOptionFields({
  values = { type: "" },
  onFieldChange,
  fields,
}: ConnectionOptionFieldsProps) {
  const sortedFields = fields.sort((a, b) => a.pos - b.pos);

  return (
    <FormFieldStack>
      {sortedFields.map((field) => {
        const fieldVal = values[field.slug];
        const value =
          fieldVal !== undefined && fieldVal !== null ? String(fieldVal) : "";

        const hint = field.help ? (
          // fdyugc: should be safe to insert html since devs manage the help text
          <Prose>
            <span dangerouslySetInnerHTML={{ __html: field.help }} />
          </Prose>
        ) : null;

        return (
          <TextField
            key={field.id}
            name={field.slug}
            label={field.literate}
            hint={hint}
            required={field.required}
            type={field.data_type as unknown as InputType}
            dataType={field.data_type}
            value={value}
            title={field.validation_regex}
            pattern={field.validation_regex}
            onChange={(value) => {
              const fixedValue = parseFieldValue(field.data_type, value);

              onFieldChange(field.slug, fixedValue);
            }}
          />
        );
      })}
    </FormFieldStack>
  );
}
