import { useSuspenseQuery } from "@tanstack/react-query";

import { streamQueryKeys } from "../../../hooks/api";
import { useFdyClient } from "../../../services/FdyClientProvider";

export function useStreamAnalysisQuery(id: string) {
  const client = useFdyClient();
  const streamAnalysisQuery = useSuspenseQuery({
    queryKey: [...streamQueryKeys.show(id), "analysis"],
    queryFn: () => client.streams.getStreamAnalysis(id),
  });

  if (!streamAnalysisQuery.data) throw new Error("No stream analysis data");

  return streamAnalysisQuery.data;
}
