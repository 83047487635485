import { Select } from "@chakra-ui/react";
import { useState } from "react";

import { FormField } from "../../ui/FormField";
import {
  OutcomeAnalysisQuery_outcomeAnalysis_overallFeatures,
  OutcomeAnalysisQuery_outcomeAnalysis_overallPerformance,
} from "./__generated__/OutcomeAnalysisQuery";

export type RecognitionGroup =
  | keyof Omit<
      OutcomeAnalysisQuery_outcomeAnalysis_overallPerformance,
      "__typename"
    >
  | keyof Omit<
      OutcomeAnalysisQuery_outcomeAnalysis_overallFeatures,
      "__typename"
    >;

export function useRecognitionGroupState(
  data:
    | OutcomeAnalysisQuery_outcomeAnalysis_overallPerformance
    | OutcomeAnalysisQuery_outcomeAnalysis_overallFeatures
) {
  const { __typename, ...otherKeys } = data;
  const availableGroups = Object.keys(otherKeys).filter((key) => {
    const val = otherKeys[key as RecognitionGroup];
    return Array.isArray(val) ? val.length > 0 : val;
  }) as RecognitionGroup[];

  const [selectedGroup, setSelectedGroup] = useState<RecognitionGroup>(
    availableGroups[0]
  );

  return {
    availableGroups,
    selectedGroup,
    setSelectedGroup,
  };
}

const labelMap: Record<RecognitionGroup, string> = {
  allIndividuals: "All Individuals",
  recognizedIndividuals: "Recognized individuals",
  unrecognizedIndividuals: "Unrecognized individuals",
};

export function OutcomeAnalysisRecognitionGroupSelect({
  value,
  onChange,
  groups,
}: {
  groups: RecognitionGroup[];
  value: RecognitionGroup;
  onChange: (group: RecognitionGroup) => void;
}) {
  // don't show if there's only one group
  if (groups.length === 1) return null;

  return (
    <FormField label="Recognition">
      <Select
        value={value}
        onChange={(e) => {
          onChange(e.target.value as RecognitionGroup);
        }}
        maxWidth={300}
        mb={4}
      >
        {groups.map((group) => {
          return (
            <option key={group} value={group}>
              {labelMap[group]}
            </option>
          );
        })}
      </Select>
    </FormField>
  );
}
