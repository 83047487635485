import { Box } from "@chakra-ui/react";

const sizes = {
  xs: "24px",
  sm: "32px",
  md: "48px",
  lg: "64px",
};

export function PersonaAvatar({
  persona,
  size = "md",
}: {
  persona: {
    name: string | null | undefined;
    avatar?: string | null | undefined;
  };
  size?: keyof typeof sizes;
}) {
  if (!persona.avatar) return null;

  return (
    <Box
      as="img"
      src={persona.avatar}
      sx={{
        width: sizes[size],
        borderRadius: 1000,
        border: "2px solid",
        borderColor: "fdy_gray.200",
      }}
    />
  );
}
