import { Box } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { DocsLink } from "../../ui/DocsLink";
import { LinkButton } from "../../ui/LinkButton";
import { PageContainer } from "../../ui/PageContainer";
import { PageHeader } from "../../ui/PageHeader";
import { PageIntroText } from "../../ui/PageIntroText";
import { PageLayout } from "../../ui/PageLayout";
import { TraitUsageWarningNotice } from "../../ui/TraitUsageWarningNotice";
import { UsageWarningNotice } from "../../ui/UsageWarningNotice";
import { PipelinesTable } from "./PipelinesTable";

/**
 * Renders the overall Pipelines list page with breadcrumbs and a table of pipelines.
 */
export function PipelinesListPage() {
  const headerCta = (
    <Box display="flex" gap={2}>
      <DocsLink resourceType="pipelines" />
      <LinkButton
        routeName={ROUTE_NAMES.PIPELINES_NEW}
        leftIcon={<Plus weight="bold" />}
        analyticsName="new-pipeline"
      >
        New Pipeline
      </LinkButton>
    </Box>
  );

  return (
    <PageLayout analyticsStackName="pipelines-list">
      <PageHeader
        title="Pipelines"
        rightContent={headerCta}
        crumbs={[
          {
            label: "Pipelines",
            routeName: ROUTE_NAMES.PIPELINES,
          },
        ]}
      />
      <PageContainer size="fdy_lg">
        <TraitUsageWarningNotice />
        <UsageWarningNotice resource="scopes" />
        <PageIntroText>
          Pipelines allow you to easily define what predictions you want, who
          you want them on, and where you want them to be deployed.
        </PageIntroText>
        <PipelinesTable />
      </PageContainer>
    </PageLayout>
  );
}
