import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Stream } from "@fdy/faraday-js";
import React from "react";

import { useDatasetListSuspenseQuery } from "../../../hooks/api";
import { date as formatDate, largeNumber } from "../../../utils/formatters";
import { CardV2 } from "../../ui/Card/CardV2";
import { IconWithText } from "../../ui/IconWithText";
import { PendingValue } from "../../ui/PendingValue";
import { ResourceIcon } from "../../ui/ResourceIcon";
import { ResourceLink } from "../../ui/ResourceLink";
import { findRelevantDatasetsByStream } from "../EventsListPage/EventsTable";

export const EventStreamDatasetsTable = ({ stream }: { stream: Stream }) => {
  const { data: datasets } = useDatasetListSuspenseQuery();

  const relevantDatasets = findRelevantDatasetsByStream(stream, datasets ?? []);

  return (
    <Table size="sm">
      <Thead>
        <Tr>
          <Th>Dataset</Th>
          <Th>Event count</Th>
          <Th>First event</Th>
          <Th>Most recent event</Th>
        </Tr>
      </Thead>
      <Tbody>
        {relevantDatasets.map((dataset) => {
          const contrib = stream.event_contribution_by_dataset?.find(
            (contrib) => contrib.dataset_id === dataset.id
          );

          return (
            <Tr key={dataset.id}>
              <Td>
                <ResourceLink
                  resource={{
                    id: dataset.id,
                    resource_type: "datasets",
                    name: dataset.name,
                  }}
                >
                  <IconWithText>
                    <ResourceIcon.datasets />
                    {dataset.name}
                  </IconWithText>
                </ResourceLink>
              </Td>
              <Td>
                <PendingValue
                  value={contrib?.event_count}
                  formatter={largeNumber}
                />
              </Td>
              <Td>
                <PendingValue
                  value={contrib?.oldest_date}
                  formatter={formatDate}
                />
              </Td>
              <Td>
                <PendingValue
                  value={contrib?.newest_date}
                  formatter={formatDate}
                />
              </Td>
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
};

export const EventStreamDatasetContributionCard = ({
  stream,
}: {
  stream: Stream;
}) => {
  return (
    <CardV2 title="Contribution">
      <EventStreamDatasetsTable stream={stream} />
    </CardV2>
  );
};
