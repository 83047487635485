import connectionTypes from "../../vannevar/connection_types.json";
import connectionTypesOptions from "../../vannevar/connection_types_options.json";
import {
  ConnectionTypeSlug,
  discriminatorMappings,
} from "../../vannevar/discriminator_mappings";
import { ConnectionsQuery_connections_options as ConnectionOptions } from "../connections/__generated__/ConnectionsQuery";
import { TargetFragment_options as TargetOptions } from "./deployment/__generated__/TargetFragment";

const connectionTypenameSlugMap: Record<
  ConnectionOptions["__typename"],
  ConnectionTypeSlug
> = Object.fromEntries(
  Object.entries(discriminatorMappings["connections"]).map(([k, v]) => [v, k])
) as Record<ConnectionOptions["__typename"], ConnectionTypeSlug>;

/** Only expose those connection types in vannevar that are in the API */
export const apiConnectionTypes = connectionTypes.filter(
  (c) => c.slug in discriminatorMappings["connections"]
);

export type ConnectionTypeInfo = (typeof apiConnectionTypes)[number];

export type ConnectionTypeOptionsInfo = (typeof connectionTypesOptions)[number];

export function connectionTypeInfosByType(type: string): ConnectionTypeInfo[] {
  const connTypes = apiConnectionTypes.filter((c) => c.type === type);
  if (!connTypes.length)
    throw new Error(`No connection types found for type: ${type}`);
  return connTypes;
}

export const PUBLICATION_CONNECTION_TYPES =
  connectionTypeInfosByType("publication");

export const MERGE_CONNECTION_TYPES = connectionTypeInfosByType("merge");

export function findConnectionTypeInfoBySlug(slug: string): ConnectionTypeInfo {
  const connType = apiConnectionTypes.find((c) => c.slug === slug);
  if (!connType) throw new Error(`No connection type found for slug: ${slug}`);
  return connType;
}

export function findConnectionTypeInfoById(id: string): ConnectionTypeInfo {
  const connType = apiConnectionTypes.find((c) => c.id === id);
  if (!connType) throw new Error(`No connection type found for id: ${id}`);
  return connType;
}

export function getConnectionTypeInfoFromTargetOptions(
  options: TargetOptions
): ConnectionTypeInfo {
  // note: avoid using TargetOptions.__typename because there's a bug in
  // openapi-graphql causing options.type to mismatch the __typename value
  return findConnectionTypeInfoBySlug(options.type);
}

export function getConnectionTypeInfoFromOptions(
  options: ConnectionOptions
): ConnectionTypeInfo {
  const slug = connectionTypenameSlugMap[options.__typename];
  return findConnectionTypeInfoBySlug(slug);
}

export function getOptionsForConnectionType({
  connectionTypeId,
  resourceType,
}: {
  connectionTypeId: string;
  resourceType: string;
}): ConnectionTypeOptionsInfo[] {
  return connectionTypesOptions.filter(
    (field) =>
      field.connection_type_id === connectionTypeId &&
      field.resource_type === resourceType
  );
}

function getTargetOptionsByConnectionTypeId(connectionTypeId: string) {
  return getOptionsForConnectionType({
    connectionTypeId,
    resourceType: "targets",
  }).filter((field) => field.read_only !== true);
}

export function getTargetOptionsByConnectionType(type: string) {
  const connectionType = findConnectionTypeInfoBySlug(type);
  return getTargetOptionsByConnectionTypeId(connectionType.id);
}

export function getTargetOptionsGroups(connectionTypeId: string) {
  const fields = getTargetOptionsByConnectionTypeId(connectionTypeId);
  const required = [];
  const optional = [];

  for (const field of fields) {
    if (field.required) {
      required.push(field);
    } else {
      optional.push(field);
    }
  }

  return {
    required,
    optional,
  };
}

/**
 * A utility for checking if a connection type is of a certain slug with type safety.
 *
 * Ideally this slug is already typed at this point but it's not always the case
 */
export function isConnectionType(
  info: ConnectionTypeInfo,
  slug: ConnectionTypeSlug
): boolean {
  return info.slug === slug;
}
