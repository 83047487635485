/**
 * This file is generated by scripts/codegen-api-hooks.ts
 * Do not modify this file directly.
 */
import type * as API from "@fdy/faraday-js";
import { useMutation, UseMutationOptions, useQuery, UseQueryOptions, useSuspenseQuery, UseSuspenseQueryOptions } from "@tanstack/react-query";

import { useFdyClient } from "../services/FdyClientProvider";

export const cohortQueryKeys = {
  list: ["cohorts"],
  show: (id: string) => [cohortQueryKeys.list, id]
};

export const useCohortShowSuspenseQuery = (cohortId: string, options?: Omit<UseSuspenseQueryOptions<API.Cohort>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Cohort>({
    queryKey: cohortQueryKeys.show(cohortId),
    queryFn: () => client.cohorts.getCohort(cohortId),
    ...options,
  });
};

export const useCohortShowQuery = (cohortId: string, options?: Omit<UseQueryOptions<API.Cohort>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Cohort>({
    queryKey: cohortQueryKeys.show(cohortId),
    queryFn: () => client.cohorts.getCohort(cohortId),
    ...options,
  });
};

export const useCohortListSuspenseQuery = (options?: Omit<UseSuspenseQueryOptions<API.Cohort[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Cohort[]>({
    queryKey: cohortQueryKeys.list,
    queryFn: () => client.cohorts.getCohorts(),
    ...options,
  });
};

export const useCohortListQuery = (options?: Omit<UseQueryOptions<API.Cohort[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Cohort[]>({
    queryKey: cohortQueryKeys.list,
    queryFn: () => client.cohorts.getCohorts(),
    ...options,
  });
};

export const useCohortCreateMutation = (options?: UseMutationOptions<API.Cohort, unknown, API.CohortPost>) => {
  const client = useFdyClient();
  return useMutation<API.Cohort, unknown, API.CohortPost>({
    mutationFn: (data) => client.cohorts.createCohort(data),
    ...options
  });
};

export const useCohortUpdateMutation = (options?: UseMutationOptions<API.Cohort, unknown, { id: string; data: API.CohortMergePatch }>) => {
  const client = useFdyClient();
  return useMutation<API.Cohort, unknown, { id: string; data: API.CohortMergePatch }>({
    mutationFn: ({ id, data }) => client.cohorts.updateCohort(id, data),
    ...options
  });
};


export const connectionQueryKeys = {
  list: ["connections"],
  show: (id: string) => [connectionQueryKeys.list, id]
};

export const useConnectionShowSuspenseQuery = (connectionId: string, options?: Omit<UseSuspenseQueryOptions<API.Connection>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Connection>({
    queryKey: connectionQueryKeys.show(connectionId),
    queryFn: () => client.connections.getConnection(connectionId),
    ...options,
  });
};

export const useConnectionShowQuery = (connectionId: string, options?: Omit<UseQueryOptions<API.Connection>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Connection>({
    queryKey: connectionQueryKeys.show(connectionId),
    queryFn: () => client.connections.getConnection(connectionId),
    ...options,
  });
};

export const useConnectionListSuspenseQuery = (options?: Omit<UseSuspenseQueryOptions<API.Connection[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Connection[]>({
    queryKey: connectionQueryKeys.list,
    queryFn: () => client.connections.getConnections(),
    ...options,
  });
};

export const useConnectionListQuery = (options?: Omit<UseQueryOptions<API.Connection[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Connection[]>({
    queryKey: connectionQueryKeys.list,
    queryFn: () => client.connections.getConnections(),
    ...options,
  });
};

export const useConnectionCreateMutation = (options?: UseMutationOptions<API.Connection, unknown, API.ConnectionPost>) => {
  const client = useFdyClient();
  return useMutation<API.Connection, unknown, API.ConnectionPost>({
    mutationFn: (data) => client.connections.createConnection(data),
    ...options
  });
};

export const useConnectionUpdateMutation = (options?: UseMutationOptions<API.Connection, unknown, { id: string; data: API.ConnectionMergePatch }>) => {
  const client = useFdyClient();
  return useMutation<API.Connection, unknown, { id: string; data: API.ConnectionMergePatch }>({
    mutationFn: ({ id, data }) => client.connections.updateConnection(id, data),
    ...options
  });
};


export const datasetQueryKeys = {
  list: ["datasets"],
  show: (id: string) => [datasetQueryKeys.list, id]
};

export const useDatasetShowSuspenseQuery = (datasetId: string, options?: Omit<UseSuspenseQueryOptions<API.Dataset>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Dataset>({
    queryKey: datasetQueryKeys.show(datasetId),
    queryFn: () => client.datasets.getDataset(datasetId),
    ...options,
  });
};

export const useDatasetShowQuery = (datasetId: string, options?: Omit<UseQueryOptions<API.Dataset>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Dataset>({
    queryKey: datasetQueryKeys.show(datasetId),
    queryFn: () => client.datasets.getDataset(datasetId),
    ...options,
  });
};

export const useDatasetListSuspenseQuery = (options?: Omit<UseSuspenseQueryOptions<API.Dataset[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Dataset[]>({
    queryKey: datasetQueryKeys.list,
    queryFn: () => client.datasets.getDatasets(),
    ...options,
  });
};

export const useDatasetListQuery = (options?: Omit<UseQueryOptions<API.Dataset[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Dataset[]>({
    queryKey: datasetQueryKeys.list,
    queryFn: () => client.datasets.getDatasets(),
    ...options,
  });
};

export const useDatasetCreateMutation = (options?: UseMutationOptions<API.Dataset, unknown, API.DatasetPost>) => {
  const client = useFdyClient();
  return useMutation<API.Dataset, unknown, API.DatasetPost>({
    mutationFn: (data) => client.datasets.createDataset(data),
    ...options
  });
};

export const useDatasetUpdateMutation = (options?: UseMutationOptions<API.Dataset, unknown, { id: string; data: API.DatasetMergePatch }>) => {
  const client = useFdyClient();
  return useMutation<API.Dataset, unknown, { id: string; data: API.DatasetMergePatch }>({
    mutationFn: ({ id, data }) => client.datasets.updateDataset(id, data),
    ...options
  });
};


export const outcomeQueryKeys = {
  list: ["outcomes"],
  show: (id: string) => [outcomeQueryKeys.list, id]
};

export const useOutcomeShowSuspenseQuery = (outcomeId: string, options?: Omit<UseSuspenseQueryOptions<API.Outcome>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Outcome>({
    queryKey: outcomeQueryKeys.show(outcomeId),
    queryFn: () => client.outcomes.getOutcome(outcomeId),
    ...options,
  });
};

export const useOutcomeShowQuery = (outcomeId: string, options?: Omit<UseQueryOptions<API.Outcome>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Outcome>({
    queryKey: outcomeQueryKeys.show(outcomeId),
    queryFn: () => client.outcomes.getOutcome(outcomeId),
    ...options,
  });
};

export const useOutcomeListSuspenseQuery = (options?: Omit<UseSuspenseQueryOptions<API.Outcome[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Outcome[]>({
    queryKey: outcomeQueryKeys.list,
    queryFn: () => client.outcomes.getOutcomes(),
    ...options,
  });
};

export const useOutcomeListQuery = (options?: Omit<UseQueryOptions<API.Outcome[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Outcome[]>({
    queryKey: outcomeQueryKeys.list,
    queryFn: () => client.outcomes.getOutcomes(),
    ...options,
  });
};

export const useOutcomeCreateMutation = (options?: UseMutationOptions<API.Outcome, unknown, API.OutcomePost>) => {
  const client = useFdyClient();
  return useMutation<API.Outcome, unknown, API.OutcomePost>({
    mutationFn: (data) => client.outcomes.createOutcome(data),
    ...options
  });
};

export const useOutcomeUpdateMutation = (options?: UseMutationOptions<API.Outcome, unknown, { id: string; data: API.OutcomeMergePatch }>) => {
  const client = useFdyClient();
  return useMutation<API.Outcome, unknown, { id: string; data: API.OutcomeMergePatch }>({
    mutationFn: ({ id, data }) => client.outcomes.updateOutcome(id, data),
    ...options
  });
};


export const persona_setQueryKeys = {
  list: ["persona_sets"],
  show: (id: string) => [persona_setQueryKeys.list, id]
};

export const usePersonaSetShowSuspenseQuery = (persona_setId: string, options?: Omit<UseSuspenseQueryOptions<API.PersonaSet>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.PersonaSet>({
    queryKey: persona_setQueryKeys.show(persona_setId),
    queryFn: () => client.personaSets.getPersonaSet(persona_setId),
    ...options,
  });
};

export const usePersonaSetShowQuery = (persona_setId: string, options?: Omit<UseQueryOptions<API.PersonaSet>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.PersonaSet>({
    queryKey: persona_setQueryKeys.show(persona_setId),
    queryFn: () => client.personaSets.getPersonaSet(persona_setId),
    ...options,
  });
};

export const usePersonaSetListSuspenseQuery = (options?: Omit<UseSuspenseQueryOptions<API.PersonaSet[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.PersonaSet[]>({
    queryKey: persona_setQueryKeys.list,
    queryFn: () => client.personaSets.getPersonaSets(),
    ...options,
  });
};

export const usePersonaSetListQuery = (options?: Omit<UseQueryOptions<API.PersonaSet[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.PersonaSet[]>({
    queryKey: persona_setQueryKeys.list,
    queryFn: () => client.personaSets.getPersonaSets(),
    ...options,
  });
};

export const usePersonaSetCreateMutation = (options?: UseMutationOptions<API.PersonaSet, unknown, API.PersonaSetPost>) => {
  const client = useFdyClient();
  return useMutation<API.PersonaSet, unknown, API.PersonaSetPost>({
    mutationFn: (data) => client.personaSets.createPersonaSet(data),
    ...options
  });
};

export const usePersonaSetUpdateMutation = (options?: UseMutationOptions<API.PersonaSet, unknown, { id: string; data: API.PersonaSetMergePatch }>) => {
  const client = useFdyClient();
  return useMutation<API.PersonaSet, unknown, { id: string; data: API.PersonaSetMergePatch }>({
    mutationFn: ({ id, data }) => client.personaSets.updatePersonaSet(id, data),
    ...options
  });
};


export const placeQueryKeys = {
  list: ["places"],
  show: (id: string) => [placeQueryKeys.list, id]
};

export const usePlaceShowSuspenseQuery = (placeId: string, options?: Omit<UseSuspenseQueryOptions<API.Place>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Place>({
    queryKey: placeQueryKeys.show(placeId),
    queryFn: () => client.places.getPlace(placeId),
    ...options,
  });
};

export const usePlaceShowQuery = (placeId: string, options?: Omit<UseQueryOptions<API.Place>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Place>({
    queryKey: placeQueryKeys.show(placeId),
    queryFn: () => client.places.getPlace(placeId),
    ...options,
  });
};

export const usePlaceListSuspenseQuery = (options?: Omit<UseSuspenseQueryOptions<API.Place[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Place[]>({
    queryKey: placeQueryKeys.list,
    queryFn: () => client.places.getPlaces(),
    ...options,
  });
};

export const usePlaceListQuery = (options?: Omit<UseQueryOptions<API.Place[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Place[]>({
    queryKey: placeQueryKeys.list,
    queryFn: () => client.places.getPlaces(),
    ...options,
  });
};

export const usePlaceCreateMutation = (options?: UseMutationOptions<API.Place, unknown, API.PlacePost>) => {
  const client = useFdyClient();
  return useMutation<API.Place, unknown, API.PlacePost>({
    mutationFn: (data) => client.places.createPlace(data),
    ...options
  });
};

export const usePlaceUpdateMutation = (options?: UseMutationOptions<API.Place, unknown, { id: string; data: API.PlaceMergePatch }>) => {
  const client = useFdyClient();
  return useMutation<API.Place, unknown, { id: string; data: API.PlaceMergePatch }>({
    mutationFn: ({ id, data }) => client.places.updatePlace(id, data),
    ...options
  });
};


export const recommenderQueryKeys = {
  list: ["recommenders"],
  show: (id: string) => [recommenderQueryKeys.list, id]
};

export const useRecommenderShowSuspenseQuery = (recommenderId: string, options?: Omit<UseSuspenseQueryOptions<API.Recommender>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Recommender>({
    queryKey: recommenderQueryKeys.show(recommenderId),
    queryFn: () => client.recommenders.getRecommender(recommenderId),
    ...options,
  });
};

export const useRecommenderShowQuery = (recommenderId: string, options?: Omit<UseQueryOptions<API.Recommender>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Recommender>({
    queryKey: recommenderQueryKeys.show(recommenderId),
    queryFn: () => client.recommenders.getRecommender(recommenderId),
    ...options,
  });
};

export const useRecommenderListSuspenseQuery = (options?: Omit<UseSuspenseQueryOptions<API.Recommender[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Recommender[]>({
    queryKey: recommenderQueryKeys.list,
    queryFn: () => client.recommenders.getRecommenders(),
    ...options,
  });
};

export const useRecommenderListQuery = (options?: Omit<UseQueryOptions<API.Recommender[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Recommender[]>({
    queryKey: recommenderQueryKeys.list,
    queryFn: () => client.recommenders.getRecommenders(),
    ...options,
  });
};

export const useRecommenderCreateMutation = (options?: UseMutationOptions<API.Recommender, unknown, API.RecommenderPost>) => {
  const client = useFdyClient();
  return useMutation<API.Recommender, unknown, API.RecommenderPost>({
    mutationFn: (data) => client.recommenders.createRecommender(data),
    ...options
  });
};

export const useRecommenderUpdateMutation = (options?: UseMutationOptions<API.Recommender, unknown, { id: string; data: API.RecommenderMergePatch }>) => {
  const client = useFdyClient();
  return useMutation<API.Recommender, unknown, { id: string; data: API.RecommenderMergePatch }>({
    mutationFn: ({ id, data }) => client.recommenders.updateRecommender(id, data),
    ...options
  });
};


export const scopeQueryKeys = {
  list: ["scopes"],
  show: (id: string) => [scopeQueryKeys.list, id]
};

export const useScopeShowSuspenseQuery = (scopeId: string, options?: Omit<UseSuspenseQueryOptions<API.Scope>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Scope>({
    queryKey: scopeQueryKeys.show(scopeId),
    queryFn: () => client.scopes.getScope(scopeId),
    ...options,
  });
};

export const useScopeShowQuery = (scopeId: string, options?: Omit<UseQueryOptions<API.Scope>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Scope>({
    queryKey: scopeQueryKeys.show(scopeId),
    queryFn: () => client.scopes.getScope(scopeId),
    ...options,
  });
};

export const useScopeListSuspenseQuery = (options?: Omit<UseSuspenseQueryOptions<API.Scope[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Scope[]>({
    queryKey: scopeQueryKeys.list,
    queryFn: () => client.scopes.getScopes(),
    ...options,
  });
};

export const useScopeListQuery = (options?: Omit<UseQueryOptions<API.Scope[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Scope[]>({
    queryKey: scopeQueryKeys.list,
    queryFn: () => client.scopes.getScopes(),
    ...options,
  });
};

export const useScopeCreateMutation = (options?: UseMutationOptions<API.Scope, unknown, API.ScopePost>) => {
  const client = useFdyClient();
  return useMutation<API.Scope, unknown, API.ScopePost>({
    mutationFn: (data) => client.scopes.createScope(data),
    ...options
  });
};

export const useScopeUpdateMutation = (options?: UseMutationOptions<API.Scope, unknown, { id: string; data: API.ScopeMergePatch }>) => {
  const client = useFdyClient();
  return useMutation<API.Scope, unknown, { id: string; data: API.ScopeMergePatch }>({
    mutationFn: ({ id, data }) => client.scopes.updateScope(id, data),
    ...options
  });
};


export const streamQueryKeys = {
  list: ["streams"],
  show: (id: string) => [streamQueryKeys.list, id]
};

export const useStreamShowSuspenseQuery = (streamId: string, options?: Omit<UseSuspenseQueryOptions<API.Stream>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Stream>({
    queryKey: streamQueryKeys.show(streamId),
    queryFn: () => client.streams.getStream(streamId),
    ...options,
  });
};

export const useStreamShowQuery = (streamId: string, options?: Omit<UseQueryOptions<API.Stream>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Stream>({
    queryKey: streamQueryKeys.show(streamId),
    queryFn: () => client.streams.getStream(streamId),
    ...options,
  });
};

export const useStreamListSuspenseQuery = (options?: Omit<UseSuspenseQueryOptions<API.Stream[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Stream[]>({
    queryKey: streamQueryKeys.list,
    queryFn: () => client.streams.getStreams(),
    ...options,
  });
};

export const useStreamListQuery = (options?: Omit<UseQueryOptions<API.Stream[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Stream[]>({
    queryKey: streamQueryKeys.list,
    queryFn: () => client.streams.getStreams(),
    ...options,
  });
};


export const targetQueryKeys = {
  list: ["targets"],
  show: (id: string) => [targetQueryKeys.list, id]
};

export const useTargetShowSuspenseQuery = (targetId: string, options?: Omit<UseSuspenseQueryOptions<API.Target>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Target>({
    queryKey: targetQueryKeys.show(targetId),
    queryFn: () => client.targets.getTarget(targetId),
    ...options,
  });
};

export const useTargetShowQuery = (targetId: string, options?: Omit<UseQueryOptions<API.Target>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Target>({
    queryKey: targetQueryKeys.show(targetId),
    queryFn: () => client.targets.getTarget(targetId),
    ...options,
  });
};

export const useTargetListSuspenseQuery = (options?: Omit<UseSuspenseQueryOptions<API.Target[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Target[]>({
    queryKey: targetQueryKeys.list,
    queryFn: () => client.targets.getTargets(),
    ...options,
  });
};

export const useTargetListQuery = (options?: Omit<UseQueryOptions<API.Target[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Target[]>({
    queryKey: targetQueryKeys.list,
    queryFn: () => client.targets.getTargets(),
    ...options,
  });
};

export const useTargetCreateMutation = (options?: UseMutationOptions<API.Target, unknown, API.TargetPost>) => {
  const client = useFdyClient();
  return useMutation<API.Target, unknown, API.TargetPost>({
    mutationFn: (data) => client.targets.createTarget(data),
    ...options
  });
};

export const useTargetUpdateMutation = (options?: UseMutationOptions<API.Target, unknown, { id: string; data: API.TargetMergePatch }>) => {
  const client = useFdyClient();
  return useMutation<API.Target, unknown, { id: string; data: API.TargetMergePatch }>({
    mutationFn: ({ id, data }) => client.targets.updateTarget(id, data),
    ...options
  });
};


export const traitQueryKeys = {
  list: ["traits"],
  show: (id: string) => [traitQueryKeys.list, id]
};

export const useTraitShowSuspenseQuery = (traitId: string, options?: Omit<UseSuspenseQueryOptions<API.Trait>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Trait>({
    queryKey: traitQueryKeys.show(traitId),
    queryFn: () => client.traits.getTrait(traitId),
    ...options,
  });
};

export const useTraitShowQuery = (traitId: string, options?: Omit<UseQueryOptions<API.Trait>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Trait>({
    queryKey: traitQueryKeys.show(traitId),
    queryFn: () => client.traits.getTrait(traitId),
    ...options,
  });
};

export const useTraitListSuspenseQuery = (options?: Omit<UseSuspenseQueryOptions<API.Trait[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useSuspenseQuery<API.Trait[]>({
    queryKey: traitQueryKeys.list,
    queryFn: () => client.traits.getTraits(),
    ...options,
  });
};

export const useTraitListQuery = (options?: Omit<UseQueryOptions<API.Trait[]>, 'queryKey'>) => {
  const client = useFdyClient();
  return useQuery<API.Trait[]>({
    queryKey: traitQueryKeys.list,
    queryFn: () => client.traits.getTraits(),
    ...options,
  });
};

