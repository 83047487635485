import { ResourceType } from "@fdy/faraday-js";

/**
 * A mappping of GraphQL __typename to REST resource type.
 *
 * No sure where to pull all possible __typenames from a single codegen file, so strings will have to do.
 */
export const gqlTypenameToResourceType: Record<string, ResourceType> = {
  Account: ResourceType.Accounts,
  Cohort: ResourceType.Cohorts,
  Connection: ResourceType.Connections,
  Dataset: ResourceType.Datasets,
  Outcome: ResourceType.Outcomes,
  PersonaSet: ResourceType.PersonaSets,
  Place: ResourceType.Places,
  Recommender: ResourceType.Recommenders,
  Scope: ResourceType.Scopes,
  Stream: ResourceType.Streams,
  Target: ResourceType.Targets,
  Trait: ResourceType.Traits,
  // fun alias issue with gql requires this
  Trait2: ResourceType.Traits,
};

export function resourceTypeToGqlTypename(resourceTypeName: string) {
  const typename = Object.keys(gqlTypenameToResourceType).find(
    (key) => gqlTypenameToResourceType[key] === resourceTypeName
  );

  if (!typename)
    throw new Error(
      `No __typename found for resource type: ${resourceTypeName}.`
    );

  return typename;
}

export function normalizeResourceType(
  resource:
    | {
        __typename: string;
      }
    | {
        resource_type: string;
      }
): ResourceType {
  if ("__typename" in resource) {
    return gqlTypenameToResourceType[resource.__typename];
  }

  return resource.resource_type as ResourceType;
}
