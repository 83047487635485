import { Recommender, ResourceType } from "@fdy/faraday-js";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { useRecommenderListSuspenseQuery } from "../../hooks/api";
import { ResourceLink } from "../ui/ResourceLink";
import { ResourceStatusBadge } from "../ui/ResourceStatus/ResourceStatusBadge";
import { ResourceTable } from "../ui/ResourceTable";
import { TimeAgo } from "../ui/TimeAgo";
import { RecommenderActionsMenu } from "./RecommenderActionsMenu";

const BLANK_SLATE_PROPS = {
  title: "No recommenders found",
  text: "Recommenders are based on Event Stream properties, which are emitted by Datasets. They are used to create Pipelines.",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children: "Start by adding a Dataset if you haven't already.",
  },
};

export function RecommendersTable() {
  const { data, isLoading } = useRecommenderListSuspenseQuery();

  const tableColumns: ColumnDef<Recommender>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 200,
        cell: ({ row }) => (
          <ResourceLink resource={row.original} sx={{ fontWeight: "bold" }} />
        ),
      },
      {
        accessorKey: "stream_name",
        header: "Event",
        cell: ({ row }) => row.original.stream_name,
      },
      {
        accessorKey: "stream_property_name",
        header: "Property",
        cell: ({ row }) => row.original.stream_property_name,
      },
      {
        accessorKey: "status",
        header: "Status",
        cell: ({ row }) => <ResourceStatusBadge resource={row.original} />,
        sortingFn: "basic",
      },
      {
        accessorKey: "created_at",
        header: "Created",
        cell: ({ row }) => <TimeAgo date={row.original.created_at} />,
        sortingFn: "datetime",
      },
      {
        accessorKey: "last_updated_config_at",
        header: "Modified",
        cell: ({ row }) => (
          <TimeAgo date={row.original.last_updated_config_at} />
        ),
        sortingFn: "datetime",
      },
      {
        accessorKey: "last_updated_output_at",
        header: "Processed",
        cell: ({ row }) => (
          <TimeAgo date={row.original.last_updated_output_at} />
        ),
        sortingFn: "datetime",
      },
      {
        id: "actions",
        header: "Actions",
        size: 64,
        enableSorting: false,
        cell: ({ row }) => (
          <RecommenderActionsMenu
            recommender={row.original}
            options={{
              view: true,
              archive: true,
            }}
            buttonProps={{ variant: "icon", my: -2 }}
          />
        ),
      },
    ],
    []
  );

  return (
    <ResourceTable<Recommender>
      resourceType={ResourceType.Recommenders}
      blankslate={BLANK_SLATE_PROPS}
      loading={isLoading}
      data={data ?? []}
      columns={tableColumns}
      defaultSort={[
        {
          id: "created_at",
          desc: true,
        },
      ]}
    />
  );
}
