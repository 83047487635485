import { Input } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { FormEvent, ReactElement, useState } from "react";

import { useToast } from "../../../hooks/useToast";
import { useFdyClient } from "../../../services/FdyClientProvider";
import { Button } from "../../ui/Button";
import { CardV2 } from "../../ui/Card/CardV2";
import { FormField } from "../../ui/FormField";
import { SettingsApiQuery_account } from "./__generated__/SettingsApiQuery";

export function AccountNameCard({
  account,
  onComplete,
}: {
  account: SettingsApiQuery_account;
  onComplete: () => void;
}): ReactElement {
  const toast = useToast();
  const [name, setName] = useState(account.name);
  const [error, setError] = useState<string>();
  const client = useFdyClient();
  const updateAccountMutation = useMutation({
    mutationFn: (name: string) =>
      client.accounts.updateAccount(account.id, { name }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Account name updated",
      });

      onComplete();
    },
  });

  const handleAccountUpdate = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError(undefined);

    const finalName = name.trim();

    if (!finalName) {
      setError("Account name is required");
      return;
    }

    if (finalName === account.name) return;

    updateAccountMutation.mutate(name);
  };

  return (
    <CardV2>
      <form onSubmit={handleAccountUpdate}>
        <FormField
          label="Account name"
          size="lg"
          helpText="Maximum of 64 characters"
          error={error}
        >
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            minLength={1}
            maxLength={64}
            required
            maxWidth={400}
          />
        </FormField>
        <Button
          type="submit"
          disabled={updateAccountMutation.isPending}
          loadingText="Saving..."
          isLoading={updateAccountMutation.isPending}
          mt={2}
          analyticsName="save-account-name"
        >
          Save
        </Button>
      </form>
    </CardV2>
  );
}
