import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";

export const unsavedChangeMessage =
  "You have not saved your changes yet. Are you sure you want to proceed?";

type NavigationWarningContextType = {
  shouldWarnBeforeNavigate: boolean;
  reset: () => void;
  setWarnBeforeNavigate: (value: boolean) => void;
  confirmNavigation: () => boolean;
};

const NavigationWarningContext =
  createContext<NavigationWarningContextType | null>(null);

interface NavigationWarningProviderProps {
  children: ReactNode;
}

/**
 * Provides a context for components to set a flag that will warn the user from navigating away from the page for use with page tabs
 * because router5 canDeactivate does not trigger for subpaths
 */
export function NavigationWarningProvider({
  children,
}: NavigationWarningProviderProps): ReactElement {
  const [shouldWarnBeforeNavigate, setWarnBeforeNavigate] =
    useState<boolean>(false);

  const reset = useCallback(() => {
    setWarnBeforeNavigate(false);
  }, []);

  const confirmNavigation = () => {
    if (shouldWarnBeforeNavigate) {
      const confirmed = window.confirm(unsavedChangeMessage);

      if (confirmed) {
        reset();
        return true;
      }

      return false;
    } else {
      return true;
    }
  };

  return (
    <NavigationWarningContext.Provider
      value={{
        setWarnBeforeNavigate,
        shouldWarnBeforeNavigate,
        reset,
        confirmNavigation,
      }}
    >
      {children}
    </NavigationWarningContext.Provider>
  );
}

export function useNavigationWarning(): NavigationWarningContextType {
  const context = useContext(NavigationWarningContext);
  if (!context)
    throw new Error(
      "useNavigationWarning must be used within a NavigationWarningProvider"
    );
  return context;
}
