import {
  ComplexDataTypeOneOfLogicalTypeEnum,
  DataMapColumnFormat,
} from "@fdy/faraday-js";
import { isMatch } from "date-fns";

import { SampleData } from "../shared/OptionWithSampleData";
import { DetectedColumn } from "../shared/types";
import { dataMapFormats } from "./datasetFormatOptionsByDataType";
import { DatasetEventCondition } from "./DatasetsEventConditionsForm";
import { DatasetEventModalStep } from "./DatasetsEventsCard";
import { EventProperty } from "./DatasetsEventsModal";
import { EventPropertyDetails } from "./PropertiesTableRow";

export type DatasetEventsError = {
  name?: string;
  datetime?: string;
  value?: string;
  properties?: string;
  conditions?: string;
};

export function stripTimeComponents(dateString: string): string {
  return dateString.replace(
    /[T\s]?\d{1,2}:\d{1,2}(:\d{1,2})?(\.\d+)?(Z|[+-]\d{2}:\d{2})?$/,
    ""
  );
}

export const namePatternHelpText =
  "Name must only contain lowercase letters, numbers, and underscores";

export function validateEventName({
  trimmedName,
  streams,
  eventStep: selectedRadio,
}: {
  trimmedName: string;
  eventStep: DatasetEventModalStep;
  streams?: { name: string }[];
}): string | undefined {
  if (!trimmedName) {
    return "Please name the event";
  }

  if (!trimmedName.match(/^[a-z0-9_]+$/)) {
    return namePatternHelpText;
  }

  if (
    streams?.find((stream) => stream.name === trimmedName) &&
    selectedRadio === DatasetEventModalStep.newStream
  ) {
    return `Name is already in use. If you want to add data to the existing '${trimmedName}' event, then go to the previous step and select 'Add to an existing event stream'. If you still want to create a new event stream, then choose a different name.`;
  }
}

const autodetectableComplexDateTypes: ComplexDataTypeOneOfLogicalTypeEnum[] = [
  ComplexDataTypeOneOfLogicalTypeEnum.Date,
  ComplexDataTypeOneOfLogicalTypeEnum.TimestampMillis,
  ComplexDataTypeOneOfLogicalTypeEnum.LocalTimestampMillis,
];

const isAutoDetectableComplexDateType = (
  selectedColumn: DetectedColumn | undefined
) => {
  if (!selectedColumn) return;
  const logicalType =
    typeof selectedColumn.dataType === "string"
      ? null
      : selectedColumn.dataType.logicalType;
  return logicalType && autodetectableComplexDateTypes.includes(logicalType);
};

export function validateDatetimeProperty(
  datetimeProperty: EventPropertyDetails,
  detectedColumns: DetectedColumn[],
  sampleData: SampleData | undefined
): DatasetEventsError | null {
  if (!datetimeProperty || !sampleData) {
    return null;
  }

  // either datetime format must be auto-detected, or "format" must be included
  if (datetimeProperty.column_name && !datetimeProperty.format) {
    const selectedColumn = detectedColumns.find(
      (column) => column.name === datetimeProperty.column_name
    );

    if (!isAutoDetectableComplexDateType(selectedColumn)) {
      return { datetime: "Date format is required" };
    }
  }

  const sampleColumnData = sampleData[String(datetimeProperty.column_name)];
  if (!sampleColumnData) {
    // possible we don't have sample data for this column?
    return null;
  }

  for (const value of sampleColumnData) {
    if (!datetimeProperty.format) {
      return null;
    }
    // we ignore the time component of date strings when we
    // convert the data_map to SQL in model_train
    const dateValue = stripTimeComponents(value);
    const formatOption =
      dataMapFormats[datetimeProperty.format as DataMapColumnFormat];

    if (
      formatOption?.dateFnsFormat &&
      !isMatch(dateValue, formatOption.dateFnsFormat)
    ) {
      // FIXME: make datetime format a warning or recommendation, not error
      // return {
      //   datetime: `The format "${formatOption.label}" is not valid for the value "${value}". Please choose a different format.`,
      // };
    }
  }
  return null;
}

function validateProperties({
  properties,
  detectedColumns,
  sampleData,
}: {
  properties: EventProperty[];
  detectedColumns: DetectedColumn[];
  sampleData: SampleData | undefined;
}): DatasetEventsError | null {
  const errors: DatasetEventsError = {};

  for (const prop of properties) {
    if (prop.name === "datetime" && prop.blessed !== true) {
      errors.properties = `Property name "datetime" is reserved. Use Datetime fields above.`;
    }

    if (prop.name === "value" && prop.blessed !== true) {
      errors.properties = `Property name "value" is reserved. Use Value fields above.`;
    }

    if (!prop.name?.trim()) {
      errors.properties = "Property name is required";
    }

    if (prop.name === "datetime" && prop.blessed) {
      const datetimeError = validateDatetimeProperty(
        prop,
        detectedColumns,
        sampleData
      );
      if (datetimeError?.datetime) {
        errors.datetime = datetimeError.datetime;
      }
    }

    // TODO: validate other date formatted properties. For now just validate blessed datetime
  }

  // ensure property names are unique
  const propertyNames = properties.filter((p) => !p.blessed).map((p) => p.name);
  if (new Set(propertyNames).size !== propertyNames.length) {
    errors.properties = "Property names must be unique";
  }

  return Object.keys(errors).length ? errors : null;
}

function validateEventOutputConditions(
  conditions: DatasetEventCondition[]
): string | undefined {
  for (const condition of conditions) {
    const { column_name, optional, ...operators } = condition;
    if (!column_name.trim()) {
      return "Conditions must have a column";
    }
    if ("" in operators) {
      return "Must select an operator";
    }
    if (!Object.keys(operators).length) {
      return "Must have at least one filter per condition";
    }
    for (const [operator, operand] of Object.entries(operators)) {
      // explicitly check operand === null so that 0 is allowed
      if (!(operator in ["_null", "_nnull"]) && operand === null) {
        return "Must specify a value";
      }
    }
  }
}

export function validateOutputToStreamEvent({
  eventName,
  properties,
  detectedColumns,
  conditions,
  sampleData,
  streams,
  eventStep,
}: {
  eventName: string;
  properties: EventProperty[];
  detectedColumns: DetectedColumn[];
  conditions: DatasetEventCondition[];
  sampleData: SampleData | undefined;
  streams?: { name: string }[];
  eventStep: DatasetEventModalStep;
}): DatasetEventsError | undefined {
  const errors: DatasetEventsError = {};

  const nameError = validateEventName({
    trimmedName: eventName,
    streams,
    eventStep,
  });
  if (nameError) {
    errors.name = nameError;
  }

  const propertiesError = validateProperties({
    properties,
    detectedColumns,
    sampleData,
  });
  if (propertiesError) {
    errors.properties = propertiesError.properties;
    errors.datetime = propertiesError.datetime;
  }

  const conditionsError = validateEventOutputConditions(conditions);
  if (conditionsError) {
    errors.conditions = conditionsError;
  }

  return Object.keys(errors).length ? errors : undefined;
}
