import { curveBasis } from "@visx/curve";
import { LineSeries } from "@visx/xychart";

import { colors } from "../../../styles/chakra-theme-v2";
import { percent } from "../../../utils/formatters";
import { AxisBottom } from "../charts-v2/AxisBottom";
import { AxisLeft } from "../charts-v2/AxisLeft";
import { Chart } from "../charts-v2/Chart";

export interface RocChartProps {
  data: { x: number; y: number }[];
}

export function RocChart({ data }: RocChartProps) {
  return (
    <Chart
      title="ROC Curve"
      yScale={{ type: "linear" }}
      xScale={{ type: "linear" }}
      aspectRatio="1"
      maxWidth={500}
    >
      <LineSeries
        dataKey="roc-curve"
        data={data}
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
        stroke={colors.fdy_magenta[500]}
        curve={curveBasis}
      />

      <LineSeries
        dataKey="diagonal"
        data={[
          { x: 0, y: 0 },
          { x: 1, y: 1 },
        ]}
        xAccessor={(d) => d.x}
        yAccessor={(d) => d.y}
        stroke={colors.fdy_gray[500]}
        strokeDasharray="2,4"
      />

      <AxisLeft label="True positive rate" tickFormat={percent} />
      <AxisBottom label="False positive rate" tickFormat={percent} />
    </Chart>
  );
}
