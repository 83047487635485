import { Recommender } from "@fdy/faraday-js";
import { useSuspenseQuery } from "@tanstack/react-query";

import { useFdyClient } from "../../services/FdyClientProvider";
import { recommenderKeys } from "./recommenderKeys";

export const useRecommenderAnalysis = (recommender: Recommender) => {
  const client = useFdyClient();
  return useSuspenseQuery({
    queryKey: recommenderKeys.analysis(recommender.id),
    queryFn: () => client.recommenders.getRecommenderAnalysis(recommender.id),
  });
};
