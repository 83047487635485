import { Container, ContainerProps } from "@chakra-ui/react";
import React, { Suspense } from "react";

import { AnimatedZapLogo } from "./AnimatedZapLogo";
import { ErrorBoundary } from "./ErrorBoundary";

export function PageContainer(props: ContainerProps) {
  return (
    <Container py={6} {...props}>
      <ErrorBoundary>
        <Suspense fallback={<AnimatedZapLogo />}>{props.children}</Suspense>
      </ErrorBoundary>
    </Container>
  );
}
