import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useRef } from "react";

import { ActionMenuChiefLink } from "../../../ui/ActionMenuChiefLink";
import { ApiShortcutsModal } from "../../../ui/ApiDocs/ApiShortcutsModal";
import { RenameModal } from "../../../ui/RenameModal";
import { ArchiveMenuButton } from "../../../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../../../ui/ResourceDeleteButton";
import { TargetFragment as Target } from "../__generated__/TargetFragment";
import { useUpdateTarget } from "./UseUpdateTarget";

export type TargetActions = {
  rename?: boolean;
  edit?: boolean;
  api?: boolean;
  archive?: boolean;
  delete?: boolean;
};

export function TargetActionsMenu({
  actions,
  onEditClick,
  target,
}: {
  target: Target;
  onEditClick: () => void;
  actions: TargetActions;
}) {
  const renameModalState = useDisclosure();
  const apiShortcutsModalProps = useDisclosure();
  const menuBtnRef = useRef<HTMLButtonElement>(null);

  const { updateTarget, updating } = useUpdateTarget({
    onCompleted() {
      renameModalState.onClose();
    },
  });
  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label={`Toggle ${target.name} actions`}
          icon={<DotsThreeOutline weight="fill" />}
          variant="ghost"
          size="sm"
          my={-2}
        />

        <MenuList>
          {actions.rename && (
            <MenuItem onClick={renameModalState.onOpen}>Rename</MenuItem>
          )}
          {actions.edit && <MenuItem onClick={onEditClick}>Edit</MenuItem>}
          {actions.api && (
            <MenuItem onClick={apiShortcutsModalProps.onOpen}>
              API Shortcuts
            </MenuItem>
          )}
          <ActionMenuChiefLink resource={target} />
          {actions.archive && (
            <ArchiveMenuButton
              name={target.name}
              resourceId={target.id}
              resourceType="targets"
              archivedAt={target.archivedAt}
              status={target.status}
            />
          )}
          {actions.delete && (
            <ResourceDeleteButton
              isMenuItem
              resourceType="targets"
              resourceId={target.id}
              name={target.name}
              resourceStatus={target.status}
            />
          )}
        </MenuList>
      </Menu>
      {/* must be outside Menu otherwise pressing tab inside the Modal doesn't work */}
      {renameModalState.isOpen && target && (
        <RenameModal
          title="Rename deployment"
          defaultValue={target.name}
          onClose={renameModalState.onClose}
          updateFn={(name) => updateTarget(target.id, { name })}
          updating={updating}
          focusAfterCloseRef={menuBtnRef}
        />
      )}
      {apiShortcutsModalProps.isOpen && target && (
        <ApiShortcutsModal
          resource={{ id: target.id, resource_type: ResourceType.Targets }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
