import { normalizeResourceType } from "../constants/gqlTypenameToResourceType";
import { ResourceType } from "./createResourceRoute";

const resourceTypeToChief: Record<ResourceType, string> = {
  accounts: "accounts",
  cohorts: "cohorts",
  connections: "connections",
  datasets: "source_tables",
  outcomes: "new_outkomes",
  persona_sets: "rosters",
  places: "user_places",
  recommenders: "recommenders",
  scopes: "scopes",
  streams: "event_streams",
  targets: "targets",
  traits: "traits",
};

const CHIEF_BASE_URL = "https://chief.faraday.ai";

export type RestOrGqlResource =
  | {
      id: string;
      resource_type: string;
    }
  | {
      id: string;
      __typename: string;
    };

export function createChiefUrl(resource: RestOrGqlResource) {
  const type = normalizeResourceType(resource);

  const chiefType = resourceTypeToChief[type];

  if (!chiefType) return CHIEF_BASE_URL;

  return `${CHIEF_BASE_URL}/${chiefType}/${resource.id}`;
}
