import Select from "react-select";

import { useStreamListSuspenseQuery } from "../../../../../hooks/api";
import { reactSelectStyle } from "../../../shared/reactSelectStyle";

export function StreamSelect({
  value,
  onChange,
  required,
}: {
  value: string;
  onChange: (value: { value: string; label: string } | null) => void;
  required?: boolean;
}) {
  const streamsQuery = useStreamListSuspenseQuery({
    select: (data) =>
      data
        .filter((stream) => !stream.archived_at)
        .sort((a, b) => a.name.localeCompare(b.name)),
  });

  const options =
    streamsQuery.data?.map((stream) => ({
      value: stream.name,
      label: stream.name,
    })) ?? [];

  return (
    <Select<{ value: string; label: string }>
      aria-label="Event stream"
      value={options.find((option) => option.value === value) ?? null}
      onChange={onChange}
      placeholder="Select an event stream"
      isDisabled={streamsQuery.isLoading}
      menuPortalTarget={document.body}
      required={required}
      styles={reactSelectStyle}
      options={options}
    />
  );
}
