import { Text } from "@chakra-ui/react";
import { Recommender } from "@fdy/faraday-js";
import { PencilSimple } from "@phosphor-icons/react";
import { useRoute } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { useRecommenderShowSuspenseQuery } from "../../hooks/api";
import { CardV2 } from "../ui/Card/CardV2";
import { CardStack } from "../ui/CardStack";
import { LinkButton } from "../ui/LinkButton";
import { ResourceGraphCard } from "../ui/ResourceGraphCard";
import {
  ResourceSummaryGrid,
  ResourceSummaryGridCell,
} from "../ui/ResourceSummaryGrid";
import { RecommenderAnalysisCards } from "./analysis";
import { RecommendersSidebarLayout } from "./RecommendersSidebarLayout";

function RecommenderSummaryText({ recommender }: { recommender: Recommender }) {
  const { stream_name, stream_property_name } = recommender;
  return (
    <Text
      as="span"
      sx={{
        fontSize: "fdy_2xl",
        color: "fdy_gray.900",
        lineHeight: "heading",
      }}
    >
      Faraday will recommend which value of the <strong>{stream_name}</strong>{" "}
      event’s <strong>{stream_property_name}</strong> property will most likely
      occur.
    </Text>
  );
}

function RecommenderDefinitionCard({
  recommender,
}: {
  recommender: Recommender;
}) {
  const { stream_name, stream_property_name } = recommender;

  return (
    <CardV2
      title="Recommender summary"
      text={<RecommenderSummaryText recommender={recommender} />}
    >
      <ResourceSummaryGrid>
        <ResourceSummaryGridCell title="Event">
          {stream_name}
        </ResourceSummaryGridCell>
        <ResourceSummaryGridCell title="Property">
          {stream_property_name}
        </ResourceSummaryGridCell>
      </ResourceSummaryGrid>

      <LinkButton
        mt={6}
        width="100%"
        variant="secondary"
        leftIcon={<PencilSimple />}
        routeName={ROUTE_NAMES.RECOMMENDERS_EDIT}
        params={{ id: recommender.id }}
        analyticsName="edit"
      >
        Edit
      </LinkButton>
    </CardV2>
  );
}

export function RecommendersShowPage() {
  const { route } = useRoute();

  const recommenderQuery = useRecommenderShowSuspenseQuery(route.params.id);

  const rec = recommenderQuery.data;
  if (!rec)
    throw new Error("No recommender found. API should throw a 40x error.");

  return (
    <RecommendersSidebarLayout
      recommender={rec}
      title={rec.name}
      lastCrumb={{
        label: rec.name,
        routeName: ROUTE_NAMES.RECOMMENDERS_SHOW,
        params: { id: rec.id },
      }}
    >
      <CardStack>
        <RecommenderDefinitionCard recommender={rec} />
        <RecommenderAnalysisCards recommender={rec} />
        <ResourceGraphCard resourceId={rec.id} />
      </CardStack>
    </RecommendersSidebarLayout>
  );
}
