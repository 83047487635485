import { useSuspenseQuery } from "@tanstack/react-query";

import { getApiBaseUrl } from "../../../services/getApiBaseUrl";
import { useAuth } from "../AuthProvider/AuthProvider";
import { MinimalResource } from "./ApiShortcuts";

/**
 * Given a resource with a possible ID, return that resource via GET /resource_type/:id.
 *
 * Skips the query if no ID is provided.
 */
export function useDynamicGetResourceQuery(resource: MinimalResource) {
  const { id, resource_type } = resource;

  const auth = useAuth();

  const query = useSuspenseQuery({
    queryKey: [resource_type, id],
    queryFn: async () => {
      // Tried to use faraday client but getting `this` undefined errors
      // within the client library. Will need to investigate further. This is
      // when using the below.
      // const method = `get${singularPascalType}`;
      // const clientGetFn = client.cohorts.getCohort;
      // if (!clientGetFn) {
      //   throw new Error(`No method found for ${method}`);
      // }

      const token = await auth.getToken();
      return fetch(`${getApiBaseUrl()}/${resource_type}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      }).then((res) => {
        // TODO: use the client so we get proper error handling
        if (!res.ok)
          throw new Error(
            `Failed to fetch API shortcuts for ${resource_type} with id: ${id}`
          );
        return res.json();
      });
    },

    // Disable the cache entirely for this query to ensure that we always
    // fetch the latest details about resources, especially if users edit
    // them. This approach is simpler than implementing a cache invalidation
    // strategy for every single resource. If this hook is used elsewhere, we
    // may consider passing this as a parameter.
    gcTime: 0,
  });

  return query;
}
