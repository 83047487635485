import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useRef } from "react";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { ActionMenuChiefLink } from "../ui/ActionMenuChiefLink";
import { ApiShortcutsModal } from "../ui/ApiDocs/ApiShortcutsModal";
import { Link } from "../ui/Link";
import { RenameModal } from "../ui/RenameModal";
import { ArchiveMenuButton } from "../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../ui/ResourceDeleteButton";
import { OutcomesQuery_outcomes } from "./__generated__/OutcomesQuery";
import { useUpdateOutcome } from "./useUpdateOutcome";

type ActionMenuOptions = {
  view?: boolean;
  info?: () => void;
  rename?: boolean;
  edit?: boolean;
  api?: boolean;
  archive?: boolean;
  delete?: boolean;
  technical_report?: boolean;
};

const defaultOptions = {
  view: false,
  rename: false,
  edit: false,
  api: false,
  archive: false,
  delete: false,
  technical_report: false,
};

/**
 * Renders a dropdown menu with various actions a user can take on a given outcome.
 */
export function OutcomeActionsMenu({
  outcome,
  onDeleteComplete,
  options,
  buttonProps,
}: {
  outcome?: OutcomesQuery_outcomes;
  options: ActionMenuOptions;
  onDeleteComplete?: () => void;
  buttonProps?: Pick<IconButtonProps, "my" | "variant">;
}) {
  const enabledOptions = { ...defaultOptions, ...options };
  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const renameModalProps = useDisclosure();
  const apiShortcutsModalProps = useDisclosure();
  const { updateOutcome, updating } = useUpdateOutcome({
    onCompleted() {
      renameModalProps.onClose();
    },
  });

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          ref={menuBtnRef}
          aria-label="Toggle Outcome actions"
          icon={<DotsThreeOutline weight="fill" />}
          color="fdy_gray.600"
          size="sm"
          variant="secondary"
          {...buttonProps}
        />
        <MenuList>
          {enabledOptions.info && (
            <MenuItem onClick={enabledOptions.info}>About outcomes</MenuItem>
          )}

          {enabledOptions.view && outcome && (
            <MenuItem
              as={Link}
              routeName={ROUTE_NAMES.OUTCOMES_DEFINITION}
              params={{ outcome: outcome.id }}
            >
              View details
            </MenuItem>
          )}

          {enabledOptions.technical_report && outcome?.reportUrl && (
            <MenuItem as="a" href={outcome.reportUrl} target="_blank">
              Technical report
            </MenuItem>
          )}

          {enabledOptions.rename && outcome && (
            <MenuItem onClick={renameModalProps.onOpen}>Rename</MenuItem>
          )}

          {enabledOptions.edit && outcome && (
            <MenuItem
              as={Link}
              routeName={ROUTE_NAMES.OUTCOMES_EDIT}
              params={{ outcome: outcome.id }}
            >
              Edit
            </MenuItem>
          )}

          {enabledOptions.api && outcome && (
            <MenuItem onClick={apiShortcutsModalProps.onOpen}>
              API shortcuts
            </MenuItem>
          )}

          {outcome && <ActionMenuChiefLink resource={outcome} />}

          {enabledOptions.archive && outcome && (
            <ArchiveMenuButton
              name={outcome.name}
              resourceId={outcome.id}
              resourceType="outcomes"
              archivedAt={outcome.archivedAt}
              focusAfterCloseRef={menuBtnRef}
              status={outcome.status}
            />
          )}

          {enabledOptions.delete && outcome && (
            <ResourceDeleteButton
              name={outcome.name}
              resourceId={outcome.id}
              resourceType="outcomes"
              onDeleteComplete={onDeleteComplete}
              isMenuItem
              resourceStatus={outcome.status}
            />
          )}
        </MenuList>
      </Menu>

      {/* modals must be outside menu otherwise menu focus handling conflicts with modal's */}

      {outcome && renameModalProps.isOpen && enabledOptions.rename && (
        <RenameModal
          title="Rename outcome"
          defaultValue={outcome.name}
          onClose={renameModalProps.onClose}
          focusAfterCloseRef={menuBtnRef}
          updating={updating}
          updateFn={(name) => updateOutcome(outcome.id, { name })}
        />
      )}

      {outcome && enabledOptions.api && apiShortcutsModalProps.isOpen && (
        <ApiShortcutsModal
          resource={{
            id: outcome.id,
            resource_type: ResourceType.Outcomes,
          }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
