import { Box } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { DocsLink } from "../../ui/DocsLink";
import { LinkButton } from "../../ui/LinkButton";
import { PageContainer } from "../../ui/PageContainer";
import { PageHeader } from "../../ui/PageHeader";
import { PageIntroText } from "../../ui/PageIntroText";
import { PageLayout } from "../../ui/PageLayout";
import { UsageWarningNotice } from "../../ui/UsageWarningNotice";
import { OutcomesTable } from "./OutcomesTable";

/**
 * Renders the overall Pipelines list page with breadcrumbs and a table of pipelines.
 */
export function OutcomesListPage() {
  const headerCta = (
    <Box display="flex" gap={2}>
      <DocsLink resourceType="outcomes" />
      <LinkButton
        routeName={ROUTE_NAMES.OUTCOMES_NEW}
        leftIcon={<Plus weight="bold" />}
        analyticsName="new-outcome"
      >
        New Outcome
      </LinkButton>
    </Box>
  );

  return (
    <PageLayout analyticsStackName="outcomes-list">
      <PageHeader
        title="Outcomes"
        rightContent={headerCta}
        crumbs={[
          {
            label: "Outcomes",
            routeName: ROUTE_NAMES.OUTCOMES,
          },
        ]}
      />
      <PageContainer size="fdy_lg">
        <UsageWarningNotice resource="outcomes" />
        <PageIntroText>
          Outcomes are how you create propensity predictions in Faraday. With
          outcomes, you'll describe your business goals–lead conversion, churn
          reduction, etc–through your cohorts, and Faraday will predict how
          likely individuals are to achieve that business goal. For each
          outcome, Faraday builds a series of predictive models and chooses the
          most predictive one for you.
        </PageIntroText>
        <OutcomesTable />
      </PageContainer>
    </PageLayout>
  );
}
