import classNames from "classnames";
import {
  CSSProperties,
  forwardRef,
  HTMLAttributes,
  MouseEvent,
  ReactElement,
  ReactNode,
} from "react";
import { useRoute } from "react-router5";
import Rollbar, { LogArgument } from "rollbar";
import { NavigationOptions } from "router5";

import { analyticsAttrs, useAnalyticsKey } from "./Analytics/AnalyticsStack";
import { useNavigationWarning } from "./NavigationWarningProvider";
import { useRollbar } from "./RollbarProvider";

function attempt<T>(rollbar: Rollbar, fn: () => T, fallback: T) {
  try {
    return fn();
  } catch (e) {
    rollbar.warn(e as LogArgument);
    return fallback;
  }
}

export interface LinkProps {
  activeClassName?: string;
  activeStrict?: boolean;
  children:
    | ReactNode
    | ((props: {
        active: boolean;
        onClick: (e: MouseEvent) => void;
        href: string | null;
      }) => ReactElement);
  className?: string;
  href?: string;
  rel?: string;
  id?: string;
  onClick?: (e: MouseEvent) => void;
  options?: NavigationOptions;
  params?: Record<string, string | string[]>;
  routeName?: string;
  style?: CSSProperties;
  target?: string;
  title?: string;
  /** override router5 active route check */
  active?: boolean;
  /**
   * Should 'active' check ignore checking uniqueness of query params?
   * @default true
   */
  activeIgnoreParams?: boolean;
  analyticsName?: string;
  "aria-label"?: string;
  "aria-current"?: HTMLAttributes<HTMLAnchorElement>["aria-current"];
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      activeClassName,
      activeStrict,
      active: forceActive,
      children,
      className,
      href,
      id,
      onClick,
      options,
      params,
      activeIgnoreParams = true,
      routeName,
      style,
      target,
      title,
      rel,
      analyticsName,
      "aria-label": ariaLabel,
      "aria-current": ariaCurrent,
    },
    ref
  ): ReactElement => {
    const { confirmNavigation } = useNavigationWarning();
    const analyticsKey = useAnalyticsKey(analyticsName);
    const { router } = useRoute();
    const rollbar: Rollbar = useRollbar();
    const routeActive = routeName
      ? router.isActive(routeName, params, activeStrict, activeIgnoreParams)
      : false;

    const active = forceActive ?? routeActive;

    const internalHref =
      href ||
      (routeName
        ? attempt(rollbar, () => router.buildPath(routeName, params), null)
        : null);

    function clickHandler(evt: MouseEvent) {
      if (onClick) {
        onClick(evt);
        if (evt.defaultPrevented) return;
      }
      const comboKey = evt.metaKey || evt.altKey || evt.ctrlKey || evt.shiftKey;

      if (!routeName) return;

      if (
        !evt ||
        ((!evt.button || evt.button === 0) && !comboKey && routeName)
      ) {
        // If the user is trying to navigate to another tab without saving config changes, warn them (router5 doesn't do this for subpaths)
        if (confirmNavigation()) {
          // always prevent the default browser behavior of the click event because it's still a link
          evt.preventDefault();
          router.navigate(routeName, params || {}, options || {});
        } else {
          // always prevent the default browser behavior of the click event because it's still a link
          evt.preventDefault();
        }
      } else if (evt.metaKey) {
        // Allow holding command while clicking link to open in new tab
        return;
      } else {
        evt.preventDefault();
        router.navigate(routeName, params || {}, options || {});
      }
    }

    if (typeof children === "function") {
      return children({
        active,
        onClick: clickHandler,
        href: internalHref,
      });
    } else {
      return (
        <a
          rel={rel}
          className={classNames(
            className,
            activeClassName && active ? activeClassName : undefined
          )}
          href={internalHref || undefined}
          id={id}
          onClick={clickHandler}
          title={title}
          style={style}
          target={target}
          ref={ref}
          aria-label={ariaLabel}
          aria-current={ariaCurrent}
          {...analyticsAttrs(analyticsKey)}
        >
          {children}
        </a>
      );
    }
  }
);
