import { gql } from "@apollo/client";
import {
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { DotsThreeOutline } from "@phosphor-icons/react";
import { useRef } from "react";

import { ROUTE_NAMES } from "../../../constants/routeNames";
import { useSojournerMutation } from "../../../services/sojournerApolloClient";
import { ActionMenuChiefLink } from "../../ui/ActionMenuChiefLink";
import { ApiShortcutsModal } from "../../ui/ApiDocs/ApiShortcutsModal";
import { Link } from "../../ui/Link";
import { RenameModal } from "../../ui/RenameModal";
import { ArchiveMenuButton } from "../../ui/ResourceArchiveButton";
import { ResourceDeleteButton } from "../../ui/ResourceDeleteButton";
import { DatasetFragment } from "../__generated__/DatasetFragment";
import {
  DatasetRenameMutation,
  DatasetRenameMutationVariables,
} from "./__generated__/DatasetRenameMutation";

export const datasetRenameMutation = gql`
  mutation DatasetRenameMutation($datasetId: ID!, $dataset: String!) {
    updateDataset(
      datasetId: $datasetId
      applicationJsonMergePatchInput: $dataset
    ) {
      id
      name
    }
  }
`;

type ActionMenuOptions = {
  view?: boolean;
  edit?: boolean;
  rename?: boolean;
  info?: () => void;
  api?: boolean;
  delete?: boolean;
  archive?: boolean;
};

const defaultOptions = {
  view: false,
  edit: false,
  rename: false,
  api: false,
  delete: false,
  archive: false,
};

/**
 * Renders a dropdown menu with various actions a user can take on a given Dataset.
 */
export function DatasetsActionsMenu({
  dataset,
  onDeleteComplete,
  options,
  buttonProps,
}: {
  dataset: Pick<
    DatasetFragment,
    "__typename" | "id" | "name" | "status" | "archivedAt"
  >;
  options: ActionMenuOptions;
  onDeleteComplete?: () => void;
  buttonProps?: Pick<IconButtonProps, "my" | "variant">;
}) {
  const enabledOptions = { ...defaultOptions, ...options };
  const menuBtnRef = useRef<HTMLButtonElement>(null);
  const renameModalProps = useDisclosure();
  const apiShortcutsModalProps = useDisclosure();

  const [editDataset, { loading }] = useSojournerMutation<
    DatasetRenameMutation,
    DatasetRenameMutationVariables
  >(datasetRenameMutation, {
    onCompleted() {
      renameModalProps.onClose();
    },
  });

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          ref={menuBtnRef}
          aria-label="Toggle Dataset actions"
          icon={<DotsThreeOutline weight="fill" />}
          variant="secondary"
          color="fdy_gray.600"
          size="sm"
          {...buttonProps}
        />

        <MenuList>
          {enabledOptions.info && (
            <MenuItem onClick={enabledOptions.info}>About datasets</MenuItem>
          )}

          {enabledOptions.view && (
            <MenuItem
              as={Link}
              routeName={ROUTE_NAMES.DATASETS_DEFINITION}
              params={{ id: dataset.id }}
            >
              View details{enabledOptions.edit ? " / edit" : ""}
            </MenuItem>
          )}

          {enabledOptions.rename && (
            <MenuItem onClick={renameModalProps.onOpen}>Rename</MenuItem>
          )}

          {enabledOptions.api && (
            <MenuItem onClick={apiShortcutsModalProps.onOpen}>
              API shortcuts
            </MenuItem>
          )}

          {dataset && <ActionMenuChiefLink resource={dataset} />}

          {enabledOptions.archive && dataset && (
            <ArchiveMenuButton
              name={dataset.name}
              resourceType="datasets"
              resourceId={dataset.id}
              archivedAt={dataset.archivedAt}
              focusAfterCloseRef={menuBtnRef}
              status={dataset.status}
            />
          )}

          {enabledOptions.delete && (
            <ResourceDeleteButton
              isMenuItem
              resourceType="datasets"
              resourceId={dataset.id}
              name={dataset.name}
              onDeleteComplete={onDeleteComplete}
              resourceStatus={dataset.status}
            />
          )}
        </MenuList>
      </Menu>

      {/* modals must be outside menu otherwise menu focus handling conflicts with modal's */}
      {renameModalProps.isOpen && (
        <RenameModal
          defaultValue={dataset.name}
          onClose={renameModalProps.onClose}
          title="Rename dataset"
          updateFn={(name) => {
            editDataset({
              variables: {
                datasetId: dataset.id,
                dataset: JSON.stringify({ name }),
              },
            });
          }}
          focusAfterCloseRef={menuBtnRef}
          updating={loading}
        />
      )}

      {enabledOptions.api && apiShortcutsModalProps.isOpen && dataset && (
        <ApiShortcutsModal
          resource={{
            id: dataset.id,
            resource_type: ResourceType.Datasets,
          }}
          onClose={apiShortcutsModalProps.onClose}
        />
      )}
    </>
  );
}
