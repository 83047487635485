import { ButtonGroup } from "@chakra-ui/react";
import { Recommender } from "@fdy/faraday-js";
import { ReactElement, ReactNode } from "react";
import { useRouter } from "react-router5";

import { ROUTE_NAMES } from "../../constants/routeNames";
import { AlertStack } from "../ui/AlertStack";
import { DependencyStatusAlert } from "../ui/DependencyStatusAlert";
import { DocsLink } from "../ui/DocsLink";
import { PageContainer } from "../ui/PageContainer";
import { Breadcrumb, PageHeader } from "../ui/PageHeader";
import { PageLayout } from "../ui/PageLayout";
import { ResourceStatusAlert } from "../ui/ResourceStatus/ResourceStatusAlert";
import { SidebarLayout } from "../ui/SidebarLayout/SidebarLayout";
import { useSidebarLayoutState } from "../ui/SidebarLayout/useSidebarLayoutState";
import { RecommenderActionsMenu } from "./RecommenderActionsMenu";
import { RecommendersInfoSidebar } from "./RecommendersInfoSidebar";

interface RecommendersSidebarLayoutProps {
  title: string;
  recommender?: Recommender;
  children: ReactNode;
  lastCrumb?: Breadcrumb;
}

/**
 * Renders the shared elements of a recommender show, edit, and create page.
 */
export function RecommendersSidebarLayout({
  title,
  recommender,
  children,
  lastCrumb,
}: RecommendersSidebarLayoutProps): ReactElement {
  const router = useRouter();

  const sidebarProps = useSidebarLayoutState("recommenders-right", {
    defaultIsOpen: false,
  });

  const crumbs = [
    {
      label: "Recommenders",
      routeName: ROUTE_NAMES.RECOMMENDERS,
    },
  ];

  if (lastCrumb) crumbs.push(lastCrumb);

  const actions = (
    <ButtonGroup>
      <DocsLink resourceType="recommenders" size="sm" />
      <RecommenderActionsMenu
        recommender={recommender}
        options={{
          info: sidebarProps.onToggle,
          archive: true,
        }}
        onDeleteComplete={() => {
          router.navigate(ROUTE_NAMES.RECOMMENDERS);
        }}
      />
    </ButtonGroup>
  );

  return (
    <PageLayout analyticsStackName="recommenders">
      <PageHeader
        title={title}
        rightContent={actions}
        crumbs={crumbs}
        backBtnProps={{
          label: "Back to Recommenders",
          routeName: ROUTE_NAMES.RECOMMENDERS,
        }}
      />
      <SidebarLayout
        rightSidebarProps={{
          isOpen: sidebarProps.isOpen,
          onClose: sidebarProps.onToggle,
          title: "About recommenders",
          children: <RecommendersInfoSidebar />,
          width: 360,
        }}
      >
        <PageContainer>
          {recommender && (
            <AlertStack>
              <DependencyStatusAlert resource={recommender} />
              <ResourceStatusAlert resource={recommender} />
            </AlertStack>
          )}
          {children}
        </PageContainer>
      </SidebarLayout>
    </PageLayout>
  );
}
