import { gql } from "@apollo/client";
import { Box, Divider, List, Stack } from "@chakra-ui/react";
import { Plus } from "@phosphor-icons/react";
import { useState } from "react";
import * as React from "react";
import { useForm } from "react-hook-form";

import { OutcomeBiasMitigationStrategy } from "../../__generated__/sojournerGlobalTypes";
import { useAccountBasics } from "../../hooks/accountConfigHooks";
import { Trait } from "../../hooks/useTraitsQuery";
import { useUnsavedChangesWarning } from "../../hooks/useUnsavedChangesWarning";
import { useSojournerQuery } from "../../services/sojournerApolloClient";
import { ActionableCardItem } from "../ui/ActionableCardItem";
import { AnalyticsStack } from "../ui/Analytics/AnalyticsStack";
import { AnimatedZapLogo } from "../ui/AnimatedZapLogo";
import { Button } from "../ui/Button";
import { CardV2 } from "../ui/Card/CardV2";
import { Checkbox } from "../ui/Checkbox";
import { FormField } from "../ui/FormField";
import { FormFieldset } from "../ui/FormFieldset";
import { Input } from "../ui/Input";
import { MutedText } from "../ui/MutedText";
import { Select } from "../ui/Select";
import { TraitSelectorModal } from "../ui/TraitSelectorModal";
import { OutcomeCohortSelectQueryV2 } from "./__generated__/OutcomeCohortSelectQueryV2";
import { OutcomeFormBias } from "./OutcomeFormBias";
import { PredictorsBlockedProvidersEnum } from "./OutcomesShowPage/OutcomeDataCard";
import { OutcomeFormState } from "./useUpdateOutcome";

export const formLabels = {
  attainment: "Attainment cohort",
  attrition: "Attrition cohort",
  eligible: "Eligibility cohort",
  providers: "Providers",
  predictors: "Predictors",
  name: "Outcome name",
};

type OutcomeError = {
  attainment?: string;
  attrition?: string;
  eligibility?: string;
  name?: string;
};

function validateOutcome(values: OutcomeFormState) {
  const {
    attainment_cohort_id,
    attrition_cohort_id,
    eligible_cohort_id,
    name,
  } = values;

  const errors: OutcomeError = {};

  const prefix = "You cannot choose the same cohort for both";

  if (!attainment_cohort_id) {
    errors.attainment = "Please select an attainment cohort";
  }

  if (attainment_cohort_id && attainment_cohort_id === attrition_cohort_id) {
    errors.attrition = `${prefix} attainment and attrition`;
  }

  if (attainment_cohort_id && attainment_cohort_id === eligible_cohort_id) {
    errors.eligibility = `${prefix} attainment and eligibility`;
  }

  if (attrition_cohort_id && attrition_cohort_id === eligible_cohort_id) {
    errors.eligibility = `${prefix} attrition and eligibility`;
  }

  if (!name.trim().length) {
    errors.name = "Please enter a name for this outcome";
  }

  if (name.trim().length > 64) {
    errors.name = "Name must be 64 characters or less";
  }

  return errors;
}

interface OutcomeFormProps {
  initialState?: OutcomeFormState;
  onSave: (formState: OutcomeFormState) => void;
  saving: boolean;
}

export const initialFormState: OutcomeFormState = {
  name: "",
  attainment_cohort_id: "",
  eligible_cohort_id: "",
  attrition_cohort_id: "",
  feature_blocklist: [],
  predictors: {
    blocked: {
      providers: [],
    },
  },
  bias_mitigation: {
    age: OutcomeBiasMitigationStrategy.NONE,
    gender: OutcomeBiasMitigationStrategy.NONE,
  },
};

export const cohortsQuery = gql`
  query OutcomeCohortSelectQueryV2 {
    cohorts {
      id
      name
      archivedAt
    }
    traits: traits3 {
      id
      name
      category
      permissions
      type
      breaks
      categories
      unit
      literate
      description
      lookupTable
      deprecated
    }
  }
`;

const PROTECTED_TRAITS = [
  "fig/age",
  "fig/child_1_birthdate",
  "fig/child_1_gender",
  "fig/child_2_birthdate",
  "fig/child_2_gender",
  "fig/child_3_birthdate",
  "fig/child_3_gender",
  "fig/child_4_birthdate",
  "fig/child_4_gender",
  "fig/children_in_household",
  "fig/date_of_birth",
  "fig/gender",
  "fig/household_type",
  "fig/marital_status",
  "fig/number_of_children",
  "fig/trigger_date_empty_nester",
  "fig/trigger_date_graduate",
  "fig/trigger_date_new_adult_to_file",
  "fig/trigger_date_newly_married",
  "fig/trigger_date_newly_single",
  "fig/trigger_date_new_young_adult_to_file",
  "fig/trigger_date_retired",
  "fig/trigger_date_value_score",
  "fig/trigger_empty_nester",
  "fig/trigger_events_graduate",
  "fig/trigger_new_adult_to_file",
  "fig/trigger_new_first_child",
  "fig/trigger_newly_married",
  "fig/trigger_newly_single",
  "fig/trigger_new_young_adult_to_file",
  "fig/trigger_retired",
  "fig/trigger_value_score",
  "fig/life_donor_other_religious_all",
  "fig/ds_lifecycle_1",
  "fig/ds_lifecycle_2",
  "fig/ds_lifecycle_3",
  "fig/ds_lifecycle_4",
  "fig/ds_lifecycle_5",
  "fig/ds_lifecycle_6",
  "fig/ds_lifecycle_7",
  "fig/ds_lifecycle_8",
  "fig/ds_lifecycle_8",
  "fig/ds_lifecycle_10",
  "fig/ds_lifecycle_11",
];

/**
 * Sharable state controlled form for creating and editing outcomes.
 */
export function OutcomeForm({
  onSave,
  initialState = initialFormState,
  saving,
}: OutcomeFormProps) {
  const accountBasics = useAccountBasics();
  const [traitModalOpen, setTraitModalOpen] = useState<boolean>(false);
  const [errors, setErrors] = useState<OutcomeError>({});
  const { handleSubmit, register, setValue, watch, formState } =
    useForm<OutcomeFormState>({
      defaultValues: initialState,
    });
  const {
    data,
    loading,
    error: cohortsError,
  } = useSojournerQuery<OutcomeCohortSelectQueryV2>(cohortsQuery);

  const cohorts = data?.cohorts ?? [];
  const traits =
    data?.traits.filter(
      (trait) =>
        // remove deprecated traits from the select (unless they are already in use)
        !trait.deprecated || initialState.feature_blocklist.includes(trait.name)
    ) ?? [];

  const [featureBlocklist, setFeatureBlocklist] = useState<Set<Trait>>(
    new Set(
      traits.filter((trait) =>
        initialState.feature_blocklist.includes(trait.name)
      )
    )
  );
  const [blockedProviders, setBlockedProviders] = useState<string[]>(
    initialState.predictors?.blocked?.providers ?? []
  );

  if (cohortsError) throw cohortsError;

  const bias = watch("bias_mitigation");

  // user warning message for unsaved changes
  const { setWarnBeforeNavigate } = useUnsavedChangesWarning({
    state: [formState.isDirty, featureBlocklist, bias],
  });

  function handleFormSubmitWithCustomErrors(values: OutcomeFormState) {
    const errorsTemp = validateOutcome(values);
    setErrors(errorsTemp);

    if (Object.keys(errorsTemp).length) return;

    const formValues = values;
    formValues.feature_blocklist = Array.from(featureBlocklist).map(
      (trait) => trait.name
    );

    formValues.predictors = {
      blocked: {
        providers: blockedProviders,
      },
    };
    onSave(formValues);
    setWarnBeforeNavigate(false);
  }

  function handleProvidersCheck(e: React.ChangeEvent<HTMLInputElement>): void {
    // once the rest of the provider spec is implemented, blocked providers can contain account uuids + "fig"
    if (e.target.checked) {
      setBlockedProviders((state) =>
        state.filter((item) => item !== PredictorsBlockedProvidersEnum.SELF)
      );
    } else {
      setBlockedProviders((state) => [
        ...state,
        PredictorsBlockedProvidersEnum.SELF,
      ]);
    }
  }

  function handleProtectedTraitsCheck(
    e: React.ChangeEvent<HTMLInputElement>
  ): void {
    if (e.target.checked) {
      setFeatureBlocklist(
        new Set([
          ...featureBlocklist,
          ...traits.filter((item) => PROTECTED_TRAITS.includes(item.name)),
        ])
      );
    } else {
      setFeatureBlocklist(
        new Set(
          Array.from(featureBlocklist).filter(
            (item) => !PROTECTED_TRAITS.includes(item.name)
          )
        )
      );
    }
  }

  const sortedCohorts = [...cohorts]
    .filter((cohort) => {
      return (
        !cohort.archivedAt ||
        [
          initialState.attainment_cohort_id,
          initialState.attrition_cohort_id,
          initialState.eligible_cohort_id,
        ].includes(cohort.id)
      );
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  if (loading) {
    return <AnimatedZapLogo />;
  }

  return (
    <AnalyticsStack value="new-outcome">
      <form onSubmit={handleSubmit(handleFormSubmitWithCustomErrors)}>
        <Box display="grid" gap={6}>
          <CardV2
            title="What defines this outcome?"
            text="In order to define an outcome, you choose up to 3 groups of people to serve in different roles. Faraday then uses these groups to automatically build and evaluate machine learning models that predict your outcome."
          >
            <FormField
              label={formLabels.eligible}
              helpText="Is it possible for anyone in Faraday's identity graph to attain this outcome? If not, you can restrict eligibility here — only people in this cohort are candidates for this outcome’s attainment."
              error={errors.eligibility}
            >
              <Select
                placeholder="Anyone in Faraday's identity graph"
                {...register("eligible_cohort_id")}
                analyticsName="eligible-cohort-select"
              >
                {sortedCohorts.map((cohort) => (
                  <option value={cohort.id} key={cohort.id}>
                    {cohort.name}
                  </option>
                ))}
              </Select>
            </FormField>
            <Divider />

            <FormField
              label={formLabels.attainment}
              helpText="What does it mean to achieve this outcome? This cohort indicates the “finish line” — people in this cohort are examples of this outcome’s attainment."
              error={errors.attainment}
            >
              <Select
                placeholder="Select a cohort..."
                {...register("attainment_cohort_id")}
                analyticsName="attainment-cohort-select"
              >
                {sortedCohorts.map((cohort) => (
                  <option value={cohort.id} key={cohort.id}>
                    {cohort.name}
                  </option>
                ))}
              </Select>
            </FormField>
            <Divider />
            <FormField
              suffix="optional"
              label={formLabels.attrition}
              helpText="Is it possible to explicitly “fail” this outcome? If so, consider choosing a cohort here to indicate this — people in this cohort are counter-examples of this outcome’s attainment. It is relatively uncommon to set this."
              error={errors.attrition}
            >
              <Select
                placeholder="Select a cohort..."
                {...register("attrition_cohort_id")}
                analyticsName="attrition-cohort-select"
              >
                {sortedCohorts.map((cohort) => (
                  <option value={cohort.id} key={cohort.id}>
                    {cohort.name}
                  </option>
                ))}
              </Select>
            </FormField>
          </CardV2>
          <CardV2
            title="Data"
            text="Faraday will use the following data to find patterns that predict your outcome."
          >
            <FormFieldset
              legend={formLabels.providers}
              hint="Use data from the following selected providers to find patterns:"
            >
              <Stack mb={4}>
                {/* 3pd will be optional once rest of predictors spec is implemented */}
                <Checkbox isDisabled isChecked={true} analyticsName="uses-3pd">
                  Faraday's built-in consumer data
                </Checkbox>
                <Checkbox
                  onChange={handleProvidersCheck}
                  isChecked={!blockedProviders.includes("self")} //if it doesnt include self, then allow 1pd
                  analyticsName="uses-1pd"
                >
                  {accountBasics.name} data
                </Checkbox>
              </Stack>
            </FormFieldset>
            <FormFieldset legend={formLabels.predictors}>
              <Checkbox
                onChange={handleProtectedTraitsCheck}
                mb={4}
                analyticsName="excludes-protected-traits"
              >
                <strong>Exclude protected traits</strong> gender, age, marital
                status, etc.
              </Checkbox>
              <Box pl={6}>
                <MutedText>
                  You can also review and correct bias after your outcome is
                  created
                </MutedText>
              </Box>
              <List display="grid" gap={2} mb={4}>
                {Array.from(featureBlocklist).map((trait) => (
                  <ActionableCardItem
                    key={trait.name}
                    compact={true}
                    title={trait.literate ?? trait.name}
                    subtitle={trait.description}
                    onDelete={() => {
                      const copy = new Set(featureBlocklist);
                      copy.delete(trait);
                      setFeatureBlocklist(copy);
                    }}
                  />
                ))}
              </List>

              <Button
                width="100%"
                variant="secondary"
                leftIcon={<Plus weight="bold" />}
                onClick={() => {
                  setTraitModalOpen(true);
                }}
                analyticsName="add-exclusion"
              >
                Add exclusion
              </Button>
            </FormFieldset>
          </CardV2>

          <OutcomeFormBias
            bias={bias}
            onChange={(val) => setValue("bias_mitigation", val)}
          />

          <CardV2>
            <FormField
              label={formLabels.name}
              helpText={
                <>
                  A strong outcome name is descriptive of its intent, and might
                  be a summary of the eligibility, attainment, and attrition
                  cohorts, such as "Lead scoring with leads from HubSpot" or
                  "Lead scoring for direct mail."
                </>
              }
              error={errors.name}
            >
              <Input
                {...register("name")}
                autoComplete="off"
                analyticsName="name"
              />
            </FormField>
          </CardV2>

          <Button
            type="submit"
            disabled={saving}
            size="lg"
            isLoading={saving}
            loadingText="Saving outcome..."
            width="full"
            analyticsName="save"
          >
            Save outcome
          </Button>
        </Box>
        <TraitSelectorModal
          isOpen={traitModalOpen}
          onClose={() => {
            setTraitModalOpen(false);
          }}
          availableTraits={traits}
          select={(t) => {
            setFeatureBlocklist(new Set([...featureBlocklist, t]));
          }}
        />
      </form>
    </AnalyticsStack>
  );
}
