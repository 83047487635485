import { gql, NetworkStatus } from "@apollo/client";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  Switch,
} from "@chakra-ui/react";
import { ResourceType } from "@fdy/faraday-js";
import { MagnifyingGlass } from "@phosphor-icons/react";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo, useState } from "react";

import {
  TraitCategory,
  TraitPermission,
  TraitTier,
} from "../../__generated__/sojournerGlobalTypes";
import { ROUTE_NAMES } from "../../constants/routeNames";
import { useSojournerQuery } from "../../services/sojournerApolloClient";
import { titleCase } from "../../utils/formatters";
import { traitLiterate } from "../../utils/traitUtils";
import { ProviderPill } from "../ui/ProviderPill";
import { ResourceTable } from "../ui/ResourceTable";
import {
  TraitsTableQuery,
  TraitsTableQuery_traits3 as Trait,
} from "./__generated__/TraitsTableQuery";
import { TraitActionsMenu } from "./TraitActionsMenu";
import {
  traitCategoryToLiterate,
  traitTierToLiterate,
} from "./traitCategoryToLiterate";
import { TraitDatasetLinks } from "./TraitDatasetLinks";

enum ProvidersOptions {
  // needed strings for the select
  all = "all",
  faraday = "faraday",
  user = "user",
}

const BLANK_SLATE_PROPS = {
  title: "No traits found",
  text: "Traits are emitted by Datasets.",
  button: {
    routeName: ROUTE_NAMES.DATASETS,
    children: "Use Datasets to add Traits.",
  },
};

export const traitsTableQuery = gql`
  query TraitsTableQuery {
    traits3 {
      id
      category
      tier
      permissions
      description
      literate
      name
      archivedAt
      status
      deprecated
    }

    # TODO: separate this query so traits load faster
    datasets {
      id
      name
      outputToTraits
      archivedAt
    }
  }
`;

/**
 * Render list of account's traits with various meta data,
 */
export function TraitsTable() {
  const [searchCriteria, setSearchCriteria] = useState("");
  const [providers, setProviders] = useState<ProvidersOptions>(
    ProvidersOptions.all
  );
  const [categories, setCategories] = useState<TraitCategory | "all">("all");
  const [tiers, setTiers] = useState<TraitTier | "all">("all");
  const [filterableOnly, setFilterableOnly] = useState<boolean>(false);
  const [appendableOnly, setAppendableOnly] = useState<boolean>(false);

  const { data, loading, error, refetch, networkStatus } =
    useSojournerQuery<TraitsTableQuery>(traitsTableQuery, {
      notifyOnNetworkStatusChange: true,
    });

  const traitsLoading = loading || networkStatus === NetworkStatus.refetch;

  if (error) throw error;

  const filteredTraits = useMemo(
    () =>
      (data?.traits3 ?? []).filter(
        (t: Trait) =>
          // remove deprecated traits
          !t.deprecated &&
          // filter by search criteria
          (!searchCriteria ||
            (t.literate ?? t.name)
              .toLowerCase()
              .includes(searchCriteria.toLowerCase())) &&
          (!appendableOnly ||
            t.permissions.includes(TraitPermission.ADD_TO_SCOPE_PAYLOAD)) &&
          (!filterableOnly ||
            t.permissions.includes(TraitPermission.DEFINE_COHORT)) &&
          // filter by provider
          (providers === ProvidersOptions.all ||
            (providers === ProvidersOptions.user &&
              t.category === TraitCategory.USER_DEFINED) ||
            (providers === ProvidersOptions.faraday &&
              t.category !== TraitCategory.USER_DEFINED)) &&
          // filter by category
          (categories === "all" || t.category === categories) &&
          // filter by tier
          (tiers === "all" || t.tier === tiers)
      ),
    [
      data,
      searchCriteria,
      filterableOnly,
      appendableOnly,
      providers,
      tiers,
      categories,
    ]
  );

  const tableHeaders: ColumnDef<Trait>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        size: 200,
        cell: ({ row }) => traitLiterate(row.original),
        sortingFn: "alphanumeric",
      },
      {
        accessorKey: "provider",
        header: "Provider",
        cell: ({ row }) => <ProviderPill provider={row.original.name} />,
      },
      {
        accessorKey: "category",
        header: "Category",
        cell: ({ row }) => traitCategoryToLiterate(row.original.category),
      },
      {
        accessorKey: "tier",
        header: "Tier",
        cell: ({ row }) => traitTierToLiterate(row.original.tier),
      },
      {
        accessorKey: "description",
        header: "Details",
        cell: ({ row }) => {
          const trait = row.original;
          return trait.category === TraitCategory.USER_DEFINED ? (
            <TraitDatasetLinks trait={trait} datasets={data?.datasets ?? []} />
          ) : (
            trait.description
          );
        },
      },
      {
        id: "actions",
        header: "Actions",
        size: 64,
        enableSorting: false,
        cell: ({ row }) => {
          if (row.original.category === TraitCategory.USER_DEFINED) {
            return (
              <TraitActionsMenu
                trait={row.original}
                onDeleteComplete={refetch}
              />
            );
          }
          return null;
        },
      },
    ],
    [data?.datasets, refetch]
  );

  return (
    <>
      <Flex gap={4} mb={4}>
        {/* TODO: move filtering to resource table */}
        <Box>
          <FormControl>
            <FormLabel>Search for traits</FormLabel>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <MagnifyingGlass />
              </InputLeftElement>
              <Input
                placeholder="Type something"
                value={searchCriteria}
                onChange={(e) => {
                  setSearchCriteria(e.target.value);
                }}
              />
            </InputGroup>
          </FormControl>
        </Box>

        <Box>
          <FormControl>
            <FormLabel>Provider</FormLabel>
            <Select
              defaultValue={providers}
              onChange={(e) => {
                setProviders(e.target.value as ProvidersOptions);
              }}
            >
              <option value={ProvidersOptions.all}>All providers</option>
              <option value={ProvidersOptions.faraday}>Faraday</option>
              <option value={ProvidersOptions.user}>User defined</option>
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel>Category</FormLabel>
            <Select
              defaultValue={categories}
              onChange={(e) => {
                setCategories(e.target.value as TraitCategory | "all");
              }}
            >
              <option value="all">All categories</option>
              {Object.keys(TraitCategory).map((category) => {
                return (
                  <option value={category} key={category}>
                    {titleCase(category.replace("FIG_", "").replace(/_/g, " "))}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl>
            <FormLabel>Tier</FormLabel>
            <Select
              defaultValue={tiers}
              onChange={(e) => {
                setTiers(e.target.value as TraitTier);
              }}
            >
              <option value="all">All tiers</option>
              {Object.keys(TraitTier).map((tier) => {
                return (
                  <option value={tier} key={tier}>
                    {titleCase(tier)}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl height="100%">
            <FormLabel>Usable in cohorts</FormLabel>
            <Switch
              size={"lg"}
              isChecked={filterableOnly}
              onChange={(e) => setFilterableOnly(e.target.checked)}
            />
          </FormControl>
        </Box>
        <Box>
          <FormControl height="100%">
            <FormLabel>Usable in pipelines</FormLabel>
            <Switch
              size={"lg"}
              isChecked={appendableOnly}
              onChange={(e) => setAppendableOnly(e.target.checked)}
            />
          </FormControl>
        </Box>
      </Flex>

      <ResourceTable<Trait>
        resourceType={ResourceType.Traits}
        blankslate={BLANK_SLATE_PROPS}
        loading={traitsLoading}
        data={filteredTraits}
        columns={tableHeaders}
        pageSize={10}
        canSelectRow={(row) =>
          row.original.category === TraitCategory.USER_DEFINED
        }
        defaultSort={[
          {
            id: "name",
            desc: false,
          },
        ]}
      />
    </>
  );
}
